import React from 'react'
import { ModalLayout } from '../modal/ModalLayout'
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";

import { AiFillInstagram } from "react-icons/ai";

const ShareModal = () => {
    return (
        <ModalLayout isVisible title={"Share Post"} dialogClass={"max-w-[450px] mx-auto"}>
            <div className="my-0">
                <div className="flex justify-around my-4">
                    {/*FACEBOOK ICON*/}
                    <a href='https://www.facebook.com/sharer/sharer.php?u=https://www.facebook.com/dialog/share?
  app_id=145634995501895
  &display=popup
  &href=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2F
  &redirect_uri=https%3A%2F%2Fdevelopers.facebook.com%2Ftools%2Fexplorer' target='_blank' className="border hover:bg-[#1877f2] w-12 h-12 text-[#1877f2] hover:text-white border-cyan-900 rounded-full flex items-center justify-center shadow-xl hover:shadow-blue-500/50 cursor-pointer">
                        <FaFacebookF size={20}/>
                    </a>
                    {/*TWITTER ICON*/}
                    <a href='#' target='_blank' className="border hover:bg-[#1d9bf0] w-12 h-12 text-[#1d9bf0] hover:text-white border-cyan-900 rounded-full flex items-center justify-center shadow-xl hover:shadow-sky-500/50 cursor-pointer">
                        <FaXTwitter size={20} />
                    </a>
                    {/*INSTAGRAM ICON*/}
                    <a href='#' target='_blank' className="border hover:bg-[#bc2a8d] w-12 h-12 text-[#bc2a8d] hover:text-white border-pink-900 rounded-full flex items-center justify-center shadow-xl hover:shadow-pink-500/50 cursor-pointer">
                        <AiFillInstagram size={25} />
                    </a>
                    {/*WHATSAPP ICON*/}
                    <a href='#' target='_blank' className="border hover:bg-[#25D366] w-12 h-12 text-[#25D366] hover:text-white border-green-900 rounded-full flex items-center justify-center shadow-xl hover:shadow-green-500/50 cursor-pointer">
                        <IoLogoWhatsapp size={25} />
                    </a>


                </div>

                {/*BOX LINK*/}
                {/* <div className="border-2 border-gray-200 flex justify-between items-center mt-4 py-2">
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" className="fill-gray-500 ml-2">
                <path d="M8.465 11.293c1.133-1.133 3.109-1.133 4.242 0l.707.707 1.414-1.414-.707-.707c-.943-.944-2.199-1.465-3.535-1.465s-2.592.521-3.535 1.465L4.929 12a5.008 5.008 0 0 0 0 7.071 4.983 4.983 0 0 0 3.535 1.462A4.982 4.982 0 0 0 12 19.071l.707-.707-1.414-1.414-.707.707a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.122-2.121z" />
                <path d="m12 4.929-.707.707 1.414 1.414.707-.707a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.122 2.121c-1.133 1.133-3.109 1.133-4.242 0L10.586 12l-1.414 1.414.707.707c.943.944 2.199 1.465 3.535 1.465s2.592-.521 3.535-1.465L19.071 12a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0z" />
                </svg>
                <input className="w-full outline-none bg-transparent" type="text" placeholder="link" defaultValue="https://boxicons.com/?query=link" />
                <button className="bg-indigo-500 text-white rounded text-sm py-2 px-5 mr-2 hover:bg-indigo-600">
                Copy
                </button>
            </div> */}
            </div>

        </ModalLayout>
    )
}

export default ShareModal