import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    imagesCategories: [],
    images: [],
    videos: [],
    videosCategories: [],
    politicalPartyLogo: [],
    politicalPartyName: [],
    
}

export const DataCollection = createSlice({
    name: 'dataCollection',
    initialState,
    reducers: {
        setImagesCategories: (state, action) => {
            state.imagesCategories = action.payload
        },
        setImages: (state, action) => {
            state.images = action.payload
        },
        setVideosCategories: (state, action) => {
            state.videosCategories = action.payload
        },
        setVideos: (state, action) => {
            state.videos = action.payload
        },
        setPoliticalPartyLogo: (state, action) => {
            state.politicalPartyLogo = action.payload
        },
        setPoliticalPartyName: (state, action) => {
            state.politicalPartyName = action.payload
        }

    },
})

// Action creators are generated for each case reducer function
export const { setImagesCategories, setImages, setVideosCategories, setVideos, setPoliticalPartyLogo, setPoliticalPartyName } = DataCollection.actions

export default DataCollection.reducer

