import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {
  LoginPage,
  RegisterPage,
  AuthLayoutOutlet,
  ForgotPasswordPage,
  ResetPasswordPage,
  LobbyLayoutOutlet,
  CategoryItemsPage,
  ProfileLayoutOutlet,
  ProfilePage,
  CreatePostPage,
  CreatePostLayoutOutlet,
  CategoryPost,
  CategoryAllPostLayoutOutlet,
  SubscriptionPage,
} from "../src/routes";
import { useAppDispatch, useAppSelector } from "./libs/hooks/hooks";
import Lobby from "./pages/lobby/Lobby";
import { setLoggedIn, setUserData } from "./libs/reducers/authentication";
import Loading from "./components/shared/Loading";
import { fireStore } from "./services/firebase/firebase";
import Home from "./pages/home";
import PageTitle from "./components/shared/PageTitle";
import AllCategoryItems from "./pages/home/AllCategoryItems";
import HomeLayout from "./pages/home/HomeLayout";
import Page404 from "./pages/404";
const App = () => {
  const isLoggedIn = useAppSelector(
    (state) => state?.authentication?.isLoggedIn
  );
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const pathname = useLocation();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (Boolean(token)) {
      dispatch(setLoggedIn(true));
      dispatch(setUserData(JSON.parse(token)));
      if (pathname.pathname !== "/") {
        navigation(pathname.pathname);
      } else {
        navigation("/");
      }
    } else {
      dispatch(setLoggedIn(false));
      dispatch(setUserData([]));
      navigation("/");
    }
    setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => {};
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route index element={<Home />} />

          <Route
            path="/allcategoryitems/:categoryname/:id"
            element={<AllCategoryItems />}
          />
          <Route path="*" element={<Page404 />} />
        </Route>
        {isLoggedIn ? (
          <Route path="/dashboard" element={<LobbyLayoutOutlet />}>
            <Route
              index
              element={
                <>
                  <PageTitle
                    title={
                      "Explore Categories || Political Poster By Appinlay "
                    }
                  />
                  <Lobby />
                </>
              }
            />
            <Route
              path="subscription"
              element={
                <>
                  <PageTitle
                    title={"Pricing || Political Poster By Appinlay "}
                  />
                  <SubscriptionPage />
                </>
              }
            />
            <Route
              path=":categoryName/:categoryId"
              element={
                <>
                  <PageTitle
                    title={"Category || Political Poster By Appinlay "}
                  />
                  <CategoryItemsPage />
                </>
              }
            />
            <Route path="profile" element={<ProfileLayoutOutlet />}>
              <Route
                index
                element={
                  <>
                    <PageTitle
                      title={"Profile || Political Poster By Appinlay "}
                    />
                    <ProfilePage />
                  </>
                }
              />
            </Route>
            <Route path="create-post" element={<CreatePostLayoutOutlet />}>
              <Route
                index
                path=":imageId"
                element={
                  <>
                    <PageTitle
                      title={"Create Post || Political Poster By Appinlay "}
                    />
                    <CreatePostPage />
                  </>
                }
              />
            </Route>
            <Route />
            <Route
              path="allpost/:categoryId"
              element={<CategoryAllPostLayoutOutlet />}
            >
              <Route
                index
                path=":imageId"
                element={
                  <>
                    <PageTitle
                      title={"Posts || Political Poster By Appinlay "}
                    />
                    <CategoryPost />
                  </>
                }
              />
            </Route>
            <Route />
          </Route>
        ) : (
          <Route path="/auth" element={<AuthLayoutOutlet />}>
            <Route
              index
              path="login"
              element={
                <>
                  <PageTitle title={"Login || Political Poster By Appinlay "} />
                  <LoginPage />
                </>
              }
            />
            <Route
              path="register"
              element={
                <>
                  <PageTitle
                    title={"Register || Political Poster By Appinlay "}
                  />
                  <RegisterPage />
                </>
              }
            />
            <Route
              path="forgot-password"
              element={
                <>
                  <PageTitle
                    title={"Forgot Password || Political Poster By Appinlay "}
                  />
                  <ForgotPasswordPage />
                </>
              }
            />
            <Route
              path="reset-password"
              element={
                <>
                  <PageTitle
                    title={"Reset Password || Political Poster By Appinlay "}
                  />
                  <ResetPasswordPage />
                </>
              }
            />
            <Route />
          </Route>
        )}
      </Routes>
    </>
  );
};

export default App;
