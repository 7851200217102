import React, { useEffect, useState } from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import FrameList from "../../../components/lists/FrameList";
import firebaseServices from "../../../services/firebase/fireabseApi";
import { imagesData } from "../../../utils/rawdata";
import { ModalLayout } from "../../../components/modal/ModalLayout";
import { IoBusinessSharp } from "react-icons/io5";
import { GiGraduateCap } from "react-icons/gi";
import { useAppSelector } from "../../../libs/hooks/hooks";

const CreatePostLayout = () => {
  const [postType, setPostType] = useState();
  const [isPostTypeModalVisible, setPostTypeModalVisible] = useState(false);
  const { imageId } = useParams();
  const [image, setImage] = useState(null);
  const imagesCollection = useAppSelector(
    (state) => state.dataCollection.images
  );

  const handleGetImage = async (imageId) => {
    if (process.env.NODE_ENV === "production") {
      const image = imagesCollection.filter((item) => item.id === imageId)[0];
      //const image = await firebaseServices.getImageById(imageId);
      setImage(image);
    } else {
      const image = imagesData.filter((item) => item.id === imageId)[0];
      setImage(image);
    }
  };

  useEffect(() => {
    if (!imageId) {
      return;
    }
    handleGetImage(imageId);
    setPostTypeModalVisible(true);
  }, []);

  return (
    <div className="p-4">
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12 order-2 md:col-span-4 md:order-1">
          <div className="bg-stone-950 shadow-bg rounded-md border border-cyan-950 h-full">
            <FrameList image={image} postType={postType} />
          </div>
        </div>
        <div className="col-span-12 order-1 md:col-span-8 md:order-2">
          <div className="bg-stone-950 shadow-bg rounded-md h-full border border-cyan-950 px-0 py-3">
            <Outlet context={{ image, postType }} />
          </div>
        </div>
      </div>
      <ModalLayout
        isVisible={isPostTypeModalVisible}
        title={"Making post for?"}
        dialogClass={"max-w-[25rem]"}
        onClose={() => {
          setPostTypeModalVisible(false);
        }}
      >
        <div className="flex items-center gap-5">
          <button
            type="button"
            onClick={() => {
              setPostType("posType=business");
              setPostTypeModalVisible(false);
            }}
            className="text-gray-400 rounded-lg transition-all hover:text-gray-100 text-center flex-1 flex-col flex items-center gap-2 border py-5 border-gray-700 hover:border-gray-100"
          >
            <IoBusinessSharp size={30} />
            <span>Business</span>
          </button>
          <button
            type="button"
            onClick={() => {
              setPostType("posType=political");
              setPostTypeModalVisible(false);
            }}
            className="text-gray-400 rounded-lg transition-all hover:text-gray-100 text-center flex-1 flex-col flex items-center gap-2 border py-5 border-gray-700 hover:border-gray-100"
          >
            <GiGraduateCap size={30} />
            <span>Political</span>
          </button>
        </div>
      </ModalLayout>
    </div>
  );
};

export default CreatePostLayout;
