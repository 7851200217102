

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    userData: []
}

export const Authentication = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload
        },
        setUserData: (state, action) => {
            state.userData = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setLoggedIn, setUserData } = Authentication.actions

export default Authentication.reducer