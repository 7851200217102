import { useState } from "react";
import Input from "../../../components/inputs/Input";
import Button from "../../../components/buttons/Button";
import { ImagePath } from "../../../utils/imagesPath";
import { IoCameraOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import firebaseServices from "../../../services/firebase/fireabseApi";
import { useAppDispatch } from "../../../libs/hooks/hooks";
import {
  setLoggedIn,
  setUserData,
} from "../../../libs/reducers/authentication";
import toast, { LoaderIcon } from "react-hot-toast";
import {
  UPLOAD_BASE_URL,
  UPLOAD_PRESET_UNSIGN,
} from "../../../services/baseurl";
import Axios from "axios";
const Register = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();
  const [userType] = useState("premium");
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(ImagePath.avatar);
  const [profileUrl, setProfileUrl] = useState(ImagePath.avatar);

  const onSubmit = async (values) => {
    setIsLoading(true);
    const isUserExist = await firebaseServices.checkIsUserExist(values.email);

    if (isUserExist.error) {
      toast.error(isUserExist.msg);
      setIsLoading(false);
    } else {
      const formData = new FormData();
      formData.append("file", profileUrl[0]);
      formData.append("upload_preset", UPLOAD_PRESET_UNSIGN);
      formData.append("folder", "images");

      const imageUrl = await Axios.post(UPLOAD_BASE_URL, formData, {
        params: { secure: true },
      });
      
      const response = await firebaseServices.register({
        name: values?.name,
        email: values?.email,
        password: values?.password,
        imageUrl: imageUrl?.data?.secure_url,
        userType,
        timestamp: Date.now(),
      });

      if (response?.created.id) {
        dispatch(setLoggedIn(false));
        dispatch(setUserData(response?.usersData));
        setIsLoading(false);
        navigation("/auth/login");
        toast.success("Your registration has been successfully completed.");
        // localStorage.setItem("token", JSON.stringify(response?.usersData));
        // if (localStorage.getItem("token")) {
        // }
      } else {
        setIsLoading(false);

        toast.error("The registration process encountered an issue.");
      }
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileUrl(file);
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file[0]);
    }
  };
  return (
    <div className="h-full flex justify-center items-center">
      <form
        className="max-w-md mx-auto w-[440px] px-5"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mb-8">
          <div className="w-32 h-32 rounded-full bg-gray-50 overflow-hidden relative">
            <img
              src={profileImage}
              alt=""
              className="w-40 h-32 object-contain"
            />
            <label
              htmlFor="input-file"
              className="absolute top-0 left-0 flex justify-center items-center z-50 w-full h-full bg-slate-800 bg-opacity-45 transition-opacity cursor-pointer opacity-0 hover:opacity-100"
            >
              <IoCameraOutline color="#fff" size={30} />
            </label>
            <input
              {...register("image", { required: "Upload your profile image" })}
              onChange={handleImageChange}
              type="file"
              id="input-file"
              className="hidden"
            />
          </div>
          {errors.image && (
            <small className="text-red-500">{errors.image.message}</small>
          )}
        </div>
        <div className="mb-5">
          <Input
            type={"text"}
            placeholder={"John deo"}
            label={"Your name"}
            isError={errors.name}
            inputProps={{
              ...register("name", { required: "Name is required" }),
            }}
          />
        </div>
        <div className="mb-5">
          <Input
            type={"email"}
            placeholder={"example@email.com"}
            label={"Your email"}
            isError={errors.email}
            inputProps={{
              ...register("email", { required: "Email is required" }),
            }}
          />
        </div>
        <div className="mb-5">
          <Input
            type={"password"}
            placeholder={"example@email.com"}
            label={"Your password"}
            isError={errors.password}
            inputProps={{
              ...register("password", { required: "Password is required" }),
            }}
          />
        </div>
        <div className="mb-5">
          <Input
            type={"password"}
            placeholder={"example@email.com"}
            label={"Confirm password"}
            isError={errors.confirmPassword}
            inputProps={{
              ...register("confirmPassword", {
                required: "Confirm is required",
              }),
            }}
          />
        </div>
        <div className="mb-5">
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id="terms"
                type="checkbox"
                {...register("terms", {
                  required: "Check the terms and conditions",
                })}
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
              />
            </div>
            <label
              htmlFor="terms"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              I agree with the{" "}
              <a
                href="#"
                className="text-blue-600 hover:underline dark:text-blue-500"
              >
                terms and conditions
              </a>
            </label>
          </div>
          {errors.terms && (
            <small className="text-red-600 text-sm">
              {errors.terms.message}
            </small>
          )}
        </div>

        {isLoading ? (
          <div className="flex items-center justify-center">
            <LoaderIcon />
          </div>
        ) : (
          <Button isDisable={isSubmitting} label={"Register"} type={"submit"} />
        )}

        <div className="pt-10">
          <p className="text-white text-center text-sm">
            Don't have account{" "}
            <Link to={"/auth/login"} className="text-blue-500">
              Login here
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Register;
