import React, { useState } from "react";
import Button from "../../../components/buttons/Button";
import Input from "../../../components/inputs/Input";
import { Link, json, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import toast, { LoaderIcon } from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "../../../libs/hooks/hooks";
import { useParams, useLocation } from "react-router-dom";

import {
  setLoggedIn,
  setUserData,
} from "../../../libs/reducers/authentication";
import firebaseServices from "../../../services/firebase/fireabseApi";

const Login = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const pathname = useLocation();
  const imageId = pathname.search.replace(/^\?/, "").replace(/=$/, "");

  const onSubmit = async (values) => {
    setIsLoading(true);
    const userData = await firebaseServices.login(
      values.email,
      values.password
    );
    if (Boolean(userData)) {
      localStorage.setItem("token", JSON.stringify(userData));
      if (localStorage.getItem("token")) {
        dispatch(setLoggedIn(true));
        dispatch(setUserData(userData));
        setIsLoading(false);
        if (imageId != "") {
          navigation(`/dashboard/create-post/${imageId}`, { replace: true });
        } else {
          navigation("/dashboard");
        }
      }
    } else {
      setIsLoading(false);

      toast.error("User not found");
    }
  };
  const handleNavigation = () => {
    const path = `/dashboard/create-post/${imageId}`;
    if (pathname.pathname === path) {
      return;
    } else {
      navigation(path);
    }
  };
  return (
    <div className="h-full flex justify-center items-center">
      <form
        className="max-w-md mx-auto w-[440px] px-5"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mb-10">
          <h3 className="text-white text-4xl font-semibold text-center">
            Login
          </h3>
        </div>
        <div className="mb-5">
          <Input
            type={"text"}
            placeholder={"example@email.com"}
            label={"Your email"}
            isError={errors.email}
            inputProps={{
              ...register("email", {
                required: "Email Address is required",
                pattern: "^[w-.]+@([w-]+.)+[w-]{2,4}$",
              }),
            }}
          />
        </div>
        <div className="mb-1">
          <Input
            type={"password"}
            placeholder={"***********"}
            label={"Your password"}
            isError={errors.password}
            inputProps={{
              ...register("password", { required: "Password is required" }),
            }}
          />
        </div>
        <div className="mb-5 text-right">
          <Link
            to={"/auth/forgot-password"}
            className="text-slate-300 hover:text-blue-500 text-sm text-right"
          >
            Forgot Password?
          </Link>
        </div>
        {isLoading ? (
          <div className="flex items-center justify-center">
            <LoaderIcon size={25} />
          </div>
        ) : (
          <Button isDisable={isSubmitting} label="Login" type={"submit"} />
        )}
        <div className="pt-10">
          <p className="text-white text-center text-sm">
            Don't have account?{" "}
            <Link to={"/auth/register"} className="text-blue-500">
              Register here
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Login;
