import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { ImagePath } from '../../utils/imagesPath'
import { HiOutlineHome } from "react-icons/hi";

const AuthLayout = () => {
  const navigation = useNavigate()
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 h-screen'>
      <div className='bg-[#fff] h-[200px] lg:h-full '>
        <button type='button' onClick={() => navigation('/')} className="text-white flex items-center px-3 py-2 absolute top-5 left-6 bg-slate-700 hover:bg-slate-950 rounded-md gap-2">
          <HiOutlineHome size={17} />
          <span className='text-sm'>Home</span>
        </button>
        <div className='flex justify-center items-center h-full'>
          <img className='' src={ImagePath.logo} />
        </div>
      </div>
      <div className='bg-[#000] h-full py-8 relative'>

        <Outlet />
      </div>
    </div>
  )
}

export default AuthLayout