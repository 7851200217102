import React, { useState } from "react";
import { BsPatchCheck } from "react-icons/bs";
import Subscription from "../subscription/Subscription";
import ContactForm from "./ContactForm";
import Tilt from "react-parallax-tilt";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useAppSelector } from "../../libs/hooks/hooks";
import { ImagePath } from "../../utils/imagesPath";
import { useNavigate, useParams } from "react-router-dom";
import CreatePostModal from "../../components/modal/CreatePostModal";
import { useLocation } from "react-router-dom";

function AllCategoryItems(props) {
  const isLoggedIn = useAppSelector(state => state.authentication.isLoggedIn)
  const [createPostModal, setCreatePostModal] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [imageId, setImageId] = useState("");
  const pathname = useLocation();
  const navigation = useNavigate();

  const handleModal = () => {
    setCreatePostModal(!createPostModal);
  };

  let { categoryname, id } = useParams();
  const categoriesImage = useAppSelector(
    (state) => state.dataCollection.imagesCategories
  );
  const images = useAppSelector((state) => state.dataCollection.images);

  const handleCreatePost = (imgUrl, imageId) => {
    setImgUrl(imgUrl);
    setImageId(imageId);
    if (isLoggedIn) {
      console.log(imageId);
      handleNavigation(imageId);
    } else {
      setCreatePostModal(true);
    }
  };
  const handleNavigation = (imageId) => {
    const path = `/dashboard/create-post/${imageId}`;
    if (pathname.pathname === path) {
      return;
    } else {
      navigation(path);
    }
  };

  return (
    <>
      <section
        className="pb-2 bg-cover bg-no-repeat"
        id="categories"
        style={{
          backgroundImage: "url(../../assets/img/hero-bg.jpg)",
        }}
      >
        <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
          <div className="max-w-screen-md mx-auto mb-10 text-center lg:mb-20">
            <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">
              Create {categoryname} Posts
            </h2>
          </div>
          {images.filter((data) => id === data?.data?.ctgIds).length === 0 && (
            <div>
              <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white text-center">
                Coming Soon
              </h2>
            </div>
          )}
          <div className="space-y-8 lg:grid lg:grid-cols-4 sm:gap-5 xl:gap-5 lg:space-y-0">
            {images
              .filter((data) => id === data?.data?.ctgIds)
              .slice(0, 20)
              .map((filteredData) => (
                <Tilt>
                  <div
                    class="categories-card"
                    onClick={() =>
                      handleCreatePost(
                        filteredData?.data?.url,
                        filteredData?.id
                      )
                    }
                  >
                    <img src={filteredData?.data?.url} alt={categoryname} />
                    <div class="text-left cursor-pointer">
                      <h3 class="font-alt">Create Post</h3>
                    </div>
                  </div>
                </Tilt>
              ))}
          </div>
        </div>
      </section>
      <CreatePostModal
        isVisible={createPostModal}
        isClose={handleModal}
        imgUrl={imgUrl}
        imageId={imageId}
      />
    </>
  );
}

export default AllCategoryItems;
