import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  fireStore,
  getDownloadURL,
  ref,
  storage,
  uploadBytes,
} from "./firebase";

class FirebaseApis {
  constructor() {
    this.client = (path) => collection(fireStore, path);
    this.clientStorage = (filename) => ref(storage, `user_profile/${filename}`);
    this.clientDoc = (path, userId) => doc(fireStore, path, userId);
  }

  async login(email, password) {
    try {
      const q = query(
        this.client("users"),
        where("email", "==", email),
        where("password", "==", password)
      );
      const querySnapshot = await getDocs(q);
      const userDoc = querySnapshot.docs[0];
      let userData = userDoc?.data();
      userData.id = userDoc.id;

      return userData ?? false;
    } catch (error) {
      console.log("Error while attempt login::", error);
    }
  }

  async checkIsUserExist(email) {
    const q = query(this.client("users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    const userDoc = querySnapshot.docs[0];
    const userDataFound = userDoc?.data();
    if (Boolean(userDataFound)) {
      return { error: true, msg: "User already exists" };
    } else {
      return { error: false, msg: "" };
    }
  }

  async getUserById(id) {
    try {
      const docRef = doc(fireStore, "users", id);
      const docSnap = await getDoc(docRef);
      let userDataFound = docSnap.data();
      userDataFound.id = docSnap.id;
      return { error: true, msg: "User Fonded", data: userDataFound };
    } catch (error) {
      return { error: false, msg: error.message };
    }
  }

  async register(userData) {
    try {
      const created = await addDoc(this.client("users"), userData);
      const docRef = doc(fireStore, "users", created?.id);
      const docSnapshot = await getDoc(docRef);
      const usersData = docSnapshot.data();
      return { usersData, created } ?? false;
    } catch (error) {
      console.log("Error while attempt register::", error);
    }
  }

  async uploadImage(image) {
    let filename = image[0].name;
    // Add timestamp to File Name
    const extension = filename.split(".").pop();
    const name = filename.split(".").slice(0, -1).join(".");
    filename = name + Date.now() + "." + extension;

    try {
      // Upload the image to Firebase Cloud Storage
      const uploadTaskSnapshot = await uploadBytes(
        this.clientStorage(filename),
        image[0]
      );

      // Get the download URL of the uploaded image
      const imageUrl = await getDownloadURL(this.clientStorage(filename));

      return imageUrl ?? false;
    } catch (e) {
      console.error("Error uploading image:", e);
      return null;
    }
  }

  async getBanners() {
    try {
      const response = await getDocs(this.client("bannerimages"));
      const images = response.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      return images ?? false;
    } catch (error) {
      console.log("Error fetching banners:", error);
    }
  }

  async getDataCollections(queryCollections) {
    try {
      let response = this.client(queryCollections);
      const assets = await getDocs(response);
      const assetsArray = assets.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      return assetsArray ?? false;
    } catch (error) {
      console.log("Error fetching collection:", error);
    }
  }

  async updateUserData(userId, data) {
    try {
      const docRef = this.clientDoc("users", userId);
      if (!docRef) throw new Error(`Invalid User ID ${userId}`);
      const response = await updateDoc(docRef, data);
      const { data: newData } = await this.getUserById(userId);
      this.updateToken(newData);
      return { error: false, msg: "User updated successfully", data: newData };
    } catch (error) {
      return {
        error: true,
        message: "Failed to retrieve user document reference.",
        err: error,
      };
    }
  }

  async updateToken(data) {
    const token = localStorage.setItem("token", JSON.stringify(data));
  }

  async getImageById(postID) {
    try {
      const q = query(this.client("images"), where("id", "==", postID));
    } catch (error) {}
  }
}
const firebaseServices = new FirebaseApis();
export default firebaseServices;
