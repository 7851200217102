import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './libs/store';
import { Toaster } from 'react-hot-toast';


import { hydrate, render } from "react-dom";

const APPRoot = (<React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <Toaster />
      <App />
    </BrowserRouter>
  </Provider>
</React.StrictMode>)


const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APPRoot, rootElement);
} else {
  render(APPRoot, rootElement);
}
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(APPRoot)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
