import React, { useEffect } from "react";
import { useState } from "react";
import { RxCross1 } from "react-icons/rx";
const ContactInfoModal = ({ isSupportVisible, handelSupport }) => {
  const [contactInformation] = useState({
    mobile: "+1234567890",
    email: "politicalpost@gmail.com",
    whatsapp: "+1234567890",
    twitter: "@example_twitter",
  });

  const handleEmailPress = () => {
    const emailUrl = `mailto:${contactInformation.email}`;
    window.open(emailUrl, "_blank");
  };

  const handlePhonePress = () => {
    const phoneUrl = `tel:${contactInformation.mobile}`;
    window.open(phoneUrl, "_blank");
  };

  const handleWhatsAppPress = () => {
    const whatsappUrl = `https://wa.me/${contactInformation.whatsapp}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div
      className={`overlay fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 ${
        isSupportVisible ? "block" : "hidden"
      }`}
    >
      <div className="flex items-center justify-center min-h-full">
        <div className="bg-white rounded-lg shadow-lg p-8 relative">
          <button
            className="absolute top-3 right-2"
            onClick={() => handelSupport()}
          >
            <RxCross1 color="#000" size={20} />
          </button>
          <div className="text-center text-lg font-bold mb-6">
            Contact Information
          </div>

          <div className="space-y-4">
            <button
              className="flex items-center text-black"
              onClick={handleEmailPress}
            >
              {/* <FeatherIcon icon="mail" size="20" className="mr-2" /> */}
              Email: {contactInformation.email}
            </button>

            <button
              className="flex items-center text-black"
              onClick={handlePhonePress}
            >
              {/* <FeatherIcon icon="phone" size="20" className="mr-2" /> */}
              Mobile: {contactInformation.mobile}
            </button>

            <button
              className="flex items-center text-black"
              onClick={handleWhatsAppPress}
            >
              {/* <FeatherIcon icon="smartphone" size="20" className="mr-2" /> */}
              WhatsApp: {contactInformation.whatsapp}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfoModal;
