import React, { useState } from "react";
import { Frames } from "../../utils/vectors/frames/Frames";
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../libs/hooks/hooks";
import { setPostFrameId } from "../../libs/reducers/createpost";
import { MdLock } from "react-icons/md";
import { maxFramesAllowed } from "../../utils/constants";
import { ModalLayout } from "../modal/ModalLayout";
import { PoliticalFrames } from "../../utils/vectors/frames/PoliticalFrames";
const FrameList = ({ image, postType }) => {
  const navigation = useNavigate();
  const userData = useAppSelector((state) => state.authentication.userData);
  const dispatch = useAppDispatch();
  const [isSubscriptionPopup, setSubscriptionPopup] = useState(false);

  // const allowedFrames =
  //   postType === "posType=political"
  //     ? Frames.slice(0, maxFramesAllowed[userData?.userType])
  //     : PoliticalFrames.slice(0, maxFramesAllowed[userData?.userType]);
  // const isFrameAllowed = (frameId) =>
  //   allowedFrames.some((item) => item.id === frameId);

  const handleShowSubscriptionPopup = (frameId, isAllowed) => {
    if (true) {
      dispatch(setPostFrameId(frameId));
    } else {
      setSubscriptionPopup(true);
    }
  };

  return (
    <>
      <div className="max-h-[88vh] h-full scrollbar-light">
        <div className="grid grid-cols-2 gap-7 p-5">
          {postType === "posType=political"
            ? PoliticalFrames.map((item, index) => {
                // const isAllowed = isFrameAllowed(item?.id);
                return (
                  <button
                    type="button"
                    onClick={() => handleShowSubscriptionPopup(item?.id)}
                    key={item?.id}
                    className={`bg-cover cursor-pointer relative aspect-square`}
                  >
                    {/* {!isAllowed && (
                      <div className="absolute z-50 p-2 bg-orange-500 text-white rounded-full top-2 right-3">
                        <MdLock />
                      </div>
                    )} */}
                    <img
                      src={image?.data?.url}
                      className="w-full h-full object-cover -z-10 relative"
                    />
                    <div className="absolute z-10 w-full h-full left-0 right-0 bottom-0">
                      {item.image()}
                    </div>
                  </button>
                );
              })
            : Frames.map((item, index) => {
                // const isAllowed = isFrameAllowed(item?.id);
                return (
                  <button
                    type="button"
                    onClick={() => handleShowSubscriptionPopup(item?.id)}
                    key={item?.id}
                    className={`bg-cover cursor-pointer relative aspect-square`}
                  >
                    {/* {!isAllowed && (
                      <div className="absolute z-50 p-2 bg-orange-500 text-white rounded-full top-2 right-3">
                        <MdLock />
                      </div>
                    )} */}
                    <img
                      src={image?.data?.url}
                      className="w-full h-full object-cover -z-10 relative"
                    />
                    <div className="absolute z-10 w-full h-full left-0 right-0 bottom-0">
                      {item.image()}
                    </div>
                  </button>
                );
              })}
        </div>
      </div>
      <ModalLayout
        title={"Upgrade Plan"}
        isVisible={isSubscriptionPopup}
        onClose={() => setSubscriptionPopup(false)}
        bodyClass={"md:p-0 p-0 space-y-0 mb-0"}
      >
        <div className="p-4 py-8">
          <p className="text-white">
            Introducing our latest update plan, designed to elevate your
            experience with enhanced features and seamless performance.
          </p>
        </div>
        <div className="py-5 px-3 flex gap-3 justify-end border-t border-t-gray-800">
          <button
            onClick={() => setSubscriptionPopup(false)}
            type="button"
            class="py-2.5 px-5  text-sm font-medium   rounded-lg focus:outline-none focus:z-10  bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => navigation("/dashboard/subscription")}
            class="focus:outline-none text-white bg-green-700 hover:bg-green-500  font-medium rounded-lg text-sm px-5 py-2.5 "
          >
            Upgrade Now
          </button>
        </div>
      </ModalLayout>
    </>
  );
};

export default FrameList;
