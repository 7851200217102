import React, { useState } from "react";
import { useAppSelector } from "../../../libs/hooks/hooks";
import PostList from "../../../components/lists/PostList";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../components/inputs/Input";
import { FaArrowLeft } from "react-icons/fa6";
import { DatePicker } from "antd";

const CategoryItems = () => {
  const params = useParams();
  const navigation = useNavigate();
  const [date, setDate] = useState("");

  const handleDateChange = (dateString) => {
    const dateObject = new Date(dateString);
    const localDateString = new Date(
      dateObject.getTime() - dateObject.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 10);
    if (localDateString === "1970-01-01") {
      setDate("");
      return;
    }
    setDate(localDateString);
  };

  const category = {
    id: params?.categoryId,
    data: {
      ctgName: params?.categoryName,
    },
  };

  return (
    <div>
      <div className="px-5 py-5 flex justify-between w-full flex:md-col">
        <button className="flex items-center" onClick={() => navigation(-1)}>
          <span className="bg-white p-2 rounded-full">
            <FaArrowLeft />
          </span>
          <span className="text-white ml-2 capitalize text-[0.9rem]">
            {params?.categoryName}
          </span>
        </button>
        {category?.data.ctgName === "upcoming posts" && (
          <div className="flex items-center gap-1">
            {/* <span className="text-white ">Date:</span> */}
            <DatePicker onChange={handleDateChange} format="YYYY-MM-DD" />
          </div>
        )}
      </div>

      <PostList
        categoryItem={category}
        key={params?.categoryId}
        isHideViewAll
        isDateShow
        isHideHeading
        date={date}
      />
    </div>
  );
};

export default CategoryItems;
