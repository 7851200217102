import React, { useEffect, useMemo, useRef, useState } from "react";
import PostCard from "../cards/PostCard";
import Button from "../buttons/Button";
import { useAppSelector } from "../../libs/hooks/hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { IoChevronForward, IoChevronBack } from "react-icons/io5";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { FormattedDate } from "../../utils/FormattedDate";

const PostList = ({
  categoryItem,
  isSwiper,
  isHideViewAll,
  isHideHeading,
  date,
  searchQuery,
}) => {
  const swiperRef = React.useRef(null);
  const navigation = useNavigate();
  const [isSlideEnd, setSliderEnd] = useState({
    isEnd: false,
    isBeginning: true,
    activeIndex: 0,
  });

  const path = `/dashboard/${
    categoryItem?.data?.videoctgName?.toLowerCase() ??
    categoryItem?.data?.ctgName?.toLowerCase()
  }/${categoryItem?.id}`;

  const images = useAppSelector((state) => state.dataCollection.images);
  const videos = useAppSelector((state) => state.dataCollection.videos);

  const filterItems = useMemo(() => {
    if (categoryItem?.data?.videoctgName) {
      return videos.filter((item) => item.data?.ctgIds === categoryItem.id);
    } else {
      if (
        categoryItem?.data.ctgName === "today posts" ||
        categoryItem?.id === "1"
      ) {
        return images.filter((item) => {
          return item.data.date === FormattedDate();
        });
      } else if (
        categoryItem?.data.ctgName === "upcoming posts" ||
        categoryItem?.id === "2"
      ) {
        if (date === "" || date === undefined) {
          return images.filter((item) => {
            return item.data.date > FormattedDate();
          });
        } else {
          return images.filter(
            (item) =>
              item.data?.date === date &&
              categoryItem?.data.ctgName === "upcoming posts"
          );
        }
      } else {
        if (date === "" || date === undefined) {
          return images.filter((item) => item.data?.ctgIds === categoryItem.id);
        } else {
          return images.filter(
            (item) =>
              item.data?.date === date && item.data?.ctgIds === categoryItem.id
          );
        }
      }
    }
  }, [videos, images, date, categoryItem]);

  const swiperSlide = () => {
    let filterImage = isSwiper ? filterItems?.slice(0, 6) : filterItems;

    return filterImage.map((item, index) => {
      if (isSwiper) {
        return (
          <SwiperSlide key={item?.id} className="max-w-32 md:max-w-60 ">
            <PostCard
              categoryName={
                categoryItem?.data?.videoctgName ?? categoryItem?.data?.ctgName
              }
              isVideo={Boolean(categoryItem?.data?.videoctgName)}
              data={item}
              catdata={categoryItem}
            />
          </SwiperSlide>
        );
      } else {
        return (
          <div key={item?.id} className="col-span-4 md:col-span-12  ">
            <PostCard
              categoryName={
                categoryItem?.data?.videoctgName ?? categoryItem?.data?.ctgName
              }
              isVideo={Boolean(categoryItem?.data?.videoctgName)}
              data={item}
              catdata={categoryItem}
              grid
            />
          </div>
        );
      }
    });
  };

  return (
    <div className="px-5 w-full">
      {!isHideHeading && !isHideViewAll && (
        <div className="flex flex-0 items-center justify-between py-5 w-full">
          {!isHideHeading && (
            <div className="flex-1">
              <h3 className="text-white capitalize">
                {categoryItem?.data?.ctgName ??
                  categoryItem?.data?.videoctgName}
              </h3>
            </div>
          )}
          {!isHideViewAll && (
            <div>
              <Button
                onClick={() => navigation(path)}
                className={
                  "w-auto lg:w-auto text-sm md:text-sm md:px-2 md:py-1 px-[0.5rem] py-[0.2rem] rounded-md "
                }
                label={"View All"}
              />
            </div>
          )}
        </div>
      )}

      <div
        className={`${
          isSwiper
            ? "flex flex-wrap gap-5"
            : "md:flex flex-wrap md:gap-5 gap-3 grid grid-cols-12"
        } `}
      >
        {!isSwiper ? (
          swiperSlide()
        ) : (
          <Swiper
            ref={swiperRef}
            modules={[Navigation, Pagination, A11y]}
            spaceBetween={20}
            slidesPerView={"auto"}
            centeredSlides={false}
            //onSlideChange={(swiper) => console.log(swiper)}
            className="swiper-list w-full"
            navigation={{
              nextEl: ".next-button",
              prevEl: ".prev-button",
            }}
          >
            {swiperSlide()}
            {/* <button
              id="previousButton"
              className="absolute block top-12 md:top-24 z-10  left-1 md:left-5 bg-white p-2 rounded-full shadow-strip"
              onClick={() => swiperRef.current.swiper.slidePrev()}
            >
              <IoChevronBack />
            </button>
            <button
              id="nextButton"
              className="absolute top-12 md:top-24 z-10 right-1 md:right-5 bg-white p-2 rounded-full shadow-strip"
              onClick={() => swiperRef.current.swiper.slideNext()}
            >
              <IoChevronForward />
            </button> */}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default PostList;
