import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../libs/hooks/hooks";
const CreatePostModal = ({ isVisible, isClose, imgUrl, imageId }) => {
  const modalRef = useRef(null);
  const isLoggedIn = useAppSelector(
    (state) => state?.authentication?.isLoggedIn
  );
  const queryParams = new URLSearchParams(imageId).toString();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isVisible &&
        modalRef.current &&
        !modalRef.current.contains(event.target)
      ) {
        isClose();
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isVisible, isClose]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isVisible &&
        modalRef.current &&
        !modalRef.current.contains(event.target)
      ) {
        isClose();
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isVisible, isClose]);
  return (
    <div
      className={`overlay fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 border-1 border-white ${
        isVisible ? "block" : "hidden"
      }`}
    >
      <div className="flex items-center justify-center min-h-full">
        <div
          ref={modalRef}
          className="bg-white rounded-lg shadow-lg p-8 relative  items-center justify-between flex flex-col gap-6 sm:flex-row"
          style={{
            backgroundImage:
              "url(https://img.freepik.com/free-vector/dark-color-pattern-abstract-style_1035-2691.jpg?w=740&t=st=1714042515~exp=1714043115~hmac=57bca228b3d3662c21bca0ea74c5f357575e8e176da89146cfb9613326b19aec)",
          }}
        >
          <div>
            <img src={imgUrl} alt="posts" height={200} width={200} />
          </div>
          <div className="flex items-center">
            {/* <div className="border-r-2 border-gray-300 pr-4 mr-4"></div>{" "}
            Divider */}
            <Link
              to={isLoggedIn ? "/dashboard" : `/auth/login?${queryParams}`}
              className="text-black bg-white hover:bg-gray-300 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 focus:outline-none"
            >
              {isLoggedIn ? "Dashboard" : "Login / Register"}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePostModal;
