import React from "react";

import { useOutletContext } from "react-router-dom";

import { useRef } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const CategoryPost = () => {
  const { image } = useOutletContext();
  const imageRef = useRef(null);
  const pathname = useLocation();
  const navigation = useNavigate();
  const params = useParams();

  const handleNavigation = () => {
    const path = `/dashboard/create-post/${params.imageId}`;
    if (pathname.pathname === path) {
      return;
    } else {
      navigation(path);
    }
  };
  return (
    <>
      <div className="max-h-[87vh] h-full scrollbar-light">
        <div className="w-full h-full flex items-center justify-evenly flex-col">
          <div className="lg:w-2/5 px-3 mb-5">
            <div
              className="bg-cover cursor-pointer relative aspect-square"
              id="htmltoimage"
              ref={imageRef}
            >
              <>
                <img
                  src={image?.data?.url}
                  className="w-full h-full object-cover -z-10 relative"
                />
              </>
            </div>
          </div>
          <div className="flex gap-3 mb-3">
            <button
              onClick={handleNavigation}
              className="bg-white transition-colors hover:bg-slate-200 px-3 py-2 rounded text-sm flex items-center gap-3"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryPost;
