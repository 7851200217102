import { BsPatchCheck } from "react-icons/bs";
import Tilt from "react-parallax-tilt";
import { useNavigate } from "react-router-dom";
import CategoriesSection from "./CategoriesSection";
import { Suspense } from "react";

const Home = () => {
  const isSnap = navigator.userAgent === "ReactSnap";
  const navigation = useNavigate();
  const generatePath = (categoryname, id) => {
    const base = `/allcategoryitems/${categoryname}/${id}`;
    window.scrollTo(0, 0);
    return base;
  };
  return (
    <>
      <section
        className="pb-28 bg-cover bg-no-repeat"
        id="home"
        style={{ backgroundImage: "url(assets/img/hero-bg.jpg)" }}
      >
        <div className="grid max-w-screen-xl px-4 pt-20 pb-36 mx-auto lg:gap-8 xl:gap-0 lg:py-36 lg:grid-cols-12 lg:pt-36">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">
              Welcome to <br /> Political Poster
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              At Political Poster, we empower you to craft captivating digital
              posts that stand out in the online world. Whether you're a
              seasoned marketer, a small business owner, or a social media
              enthusiast, our platform provides the tools and resources you need
              to create engaging content that resonates with your audience.
            </p>
            <div className="">
              <a href="">
                {/* <img src='assets/img/google-play-badge.svg' className='max-w-40' /> */}
              </a>
            </div>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <Tilt>
              <img
                src="assets/img/Designer.jpeg"
                alt="hero image"
                className="shadow rounded-lg"
              />
            </Tilt>
          </div>
        </div>
        <div className="max-w-screen-xl px-4 grid lg:grid-cols-12 md:grid-cols-3 grid-cols-1 lg:gap-4 gap-2 mx-auto mt-8 items-center">
          <div className="lg:col-span-4">
            <div className="grid grid-cols-2 gap-4 items-center">
              <div className="grid grid-cols-1 gap-4">
                <Tilt>
                  <img
                    src="assets/img/no-tambaco.jpg"
                    className="shadow rounded-lg"
                    alt="no-tobacco"
                  />
                </Tilt>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <Tilt>
                  <img
                    src="assets/img/gandhi-jayanti.avif"
                    className="shadow rounded-lg"
                    alt="gandhi-jayanti"
                  />
                </Tilt>
                <Tilt>
                  <img
                    src="assets/img/earth-globe-day-world-environment-day-vector_7943503-1.jpg"
                    className="shadow rounded-lg"
                    alt="earth-globe-day-world-environment-day"
                  />
                </Tilt>
              </div>
            </div>
          </div>
          <div className="lg:col-span-4">
            <Tilt>
              <img
                src="assets/img/world-environment-day.jpg"
                className="shadow rounded-lg"
                alt="world-environment-day"
              />
            </Tilt>
          </div>
          <div className="lg:col-span-4">
            <div className="grid grid-cols-2 gap-4 items-center">
              <div className="grid grid-cols-1 gap-4">
                <Tilt>
                  <img
                    src="assets/img/Teacher-Day.webp"
                    className="shadow rounded-lg"
                    alt="Teacher-Day"
                  />
                </Tilt>
                <Tilt>
                  <img
                    src="assets/img/childrens-day.jpg"
                    className="shadow rounded-lg"
                    alt="childrens-day"
                  />
                </Tilt>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <Tilt>
                  <img
                    src="assets/img/labur-day.jpg"
                    className="shadow rounded-lg"
                    alt="labour-day"
                  />
                </Tilt>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="bg-gradient-to-r from-amber-200 to-amber-600 p-[1px]">
        <div className="bg-[#171717]">
          <h2 className="text-4xl  font-bold text-center py-24 leading-relaxed bg-gradient-to-r from-amber-200 to-amber-600 bg-clip-text text-transparent">
            TRUSTED BY 1+ MILLION USERS AND BUSINESSES
          </h2>
        </div>
      </section>

      <section
        className="bg-contain bg-repeat-x "
        id="features"
        style={{
          backgroundImage:
            "url(https://img.freepik.com/free-vector/dark-color-pattern-abstract-style_1035-2691.jpg?w=740&t=st=1714042515~exp=1714043115~hmac=57bca228b3d3662c21bca0ea74c5f357575e8e176da89146cfb9613326b19aec)",
        }}
      >
        <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
          <div className="max-w-screen-md mx-auto mb-10 text-center lg:mb-20">
            <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">
              We Cover 360° Branding Solution App
            </h2>
          </div>
          <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
            {/* Pricing Card */}

            <div class="p-8 bg-[#01030f] border border-[#2e2e2e] border-solid rounded-md shadow-2xl shadow-slate-300/20 flex gap-5">
              <div className="text-gradient">
                <BsPatchCheck color="#fff" size={50} />
              </div>
              <div class="text-left">
                <h3 class="font-alt text-white text-2xl font-bold">
                  Festival Post
                </h3>
                <p class=" mb-0 text-gray-400">
                  Post every festival image of your brand on the social media
                  platforms
                </p>
              </div>
            </div>

            <div class="p-8 bg-[#01030f] border border-[#2e2e2e] border-solid rounded-md shadow-2xl shadow-slate-300/20 flex gap-5">
              <div className="text-gradient">
                <BsPatchCheck color="#fff" size={50} />
              </div>
              <div class="text-left">
                <h3 class="font-alt text-white text-2xl font-bold">
                  Business Post
                </h3>
                <p class="text-gray-400">
                  Marketing resources that are suitable for all your social
                  media postings
                </p>
              </div>
            </div>

            <div class="p-8 bg-[#01030f] border border-[#2e2e2e] border-solid rounded-md shadow-2xl shadow-slate-300/20 flex gap-5">
              <div className="text-gradient">
                <BsPatchCheck color="#fff" size={50} />
              </div>
              <div class="text-left">
                <h3 class="font-alt text-white text-2xl font-bold">
                  Political Post
                </h3>
                <p class="text-gray-400">
                  Graphic political post that keeps your party active on all the
                  social networks
                </p>
              </div>
            </div>

            <div class="p-8 bg-[#01030f] border border-[#2e2e2e] border-solid rounded-md shadow-2xl shadow-slate-300/20 flex gap-5">
              <div className="text-gradient">
                <BsPatchCheck color="#fff" size={50} />
              </div>
              <div class="text-left">
                <h3 class="font-alt text-white text-2xl font-bold">
                  Trending Post
                </h3>
                <p class="text-gray-400">
                  Be a trend follower. Graphic designed according to current
                  market trends.
                </p>
              </div>
            </div>

            <div class="p-8 bg-[#01030f] border border-[#2e2e2e] border-solid rounded-md shadow-2xl shadow-slate-300/20 flex gap-5">
              <div className="text-gradient">
                <BsPatchCheck color="#fff" size={50} />
              </div>
              <div class="text-left">
                <h3 class="font-alt text-white text-2xl font-bold">
                  Personalize Post
                </h3>
                <p class="text-gray-400">
                  Personalised posts options according to your business
                  requirements.
                </p>
              </div>
            </div>

            <div class="p-8 bg-[#01030f] border border-[#2e2e2e] border-solid rounded-md shadow-2xl shadow-slate-300/20 flex gap-5">
              <div className="text-gradient">
                <BsPatchCheck color="#fff" size={50} />
              </div>
              <div class="text-left">
                <h3 class="font-alt text-white text-2xl font-bold">
                  Custom Post
                </h3>
                <p class="text-gray-400">
                  Customised post options available so that you can Create your
                  own posts according to your Requirment
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="max-w-screen overflow-hidden  marque-text-area bg-gradient-to-r from-amber-200 to-amber-600 py-[1px]">
        <div className="py-20 bg-black">
          <ul>
            <li>
              <a href="#">Political Post</a>
            </li>
            <li>
              <a href="#">Festival Post</a>
            </li>
            <li>
              <a href="#">Business Post</a>
            </li>
            <li>
              <a href="#">Trending Post</a>
            </li>
            <li>
              <a href="#">Business Custom Post</a>
            </li>
            <li>
              <a href="#">Personalize Post</a>
            </li>
            <li>
              <a href="#">Motivational Post</a>
            </li>
            <li>
              <a href="#">Political Post</a>
            </li>
            <li>
              <a href="#">Festival Post</a>
            </li>
            <li>
              <a href="#">Business Post</a>
            </li>
            <li>
              <a href="#">Trending Post</a>
            </li>
            <li>
              <a href="#">Business Custom Post</a>
            </li>
            <li>
              <a href="#">Personalize Post</a>
            </li>
            <li>
              <a href="#">Motivational Post</a>
            </li>
          </ul>
        </div>
      </section>

      <section className="" id="categories">
        <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
          <div className="max-w-screen-md mx-auto mb-10 text-center lg:mb-20">
            <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">
              Explore Popular Categories
            </h2>
          </div>
          <div className="space-y-8 lg:grid lg:grid-cols-4 sm:gap-5 xl:gap-5 lg:space-y-0">
            {!isSnap && <CategoriesSection />}

            <Tilt>
              <div
                class="categories-card"
                onClick={() => navigation(generatePath("Good Night", "1"))}
              >
                <img
                  src="assets/img/category/good-night-image-add-your-821233-x534c-fb.jpg"
                  alt="Good Night"
                />
                <div class="text-left">
                  <h3 class="font-alt">Good Night</h3>
                </div>
              </div>
            </Tilt>
            <Tilt>
              <div class="categories-card">
                <img
                  src="assets/img/category/business-ethics.jpg"
                  alt=">Business Ethics"
                />
                <div class="text-left">
                  <h3 class="font-alt">Business Ethics</h3>
                </div>
              </div>
            </Tilt>
            <Tilt>
              <div
                class="categories-card"
                onClick={() => navigation(generatePath("Good Evening", "3"))}
              >
                <img
                  src="assets/img/category/good-evening.jpg"
                  alt="Good Evening"
                />
                <div class="text-left">
                  <h3 class="font-alt">Good Evening</h3>
                </div>
              </div>
            </Tilt>
            <Tilt>
              <div
                class="categories-card"
                onClick={() => navigation(generatePath("Good Morning", "4"))}
              >
                <img
                  src="assets/img/category/good-morning.jpg"
                  alt="Good Morning"
                />
                <div class="text-left">
                  <h3 class="font-alt">Good Morning</h3>
                </div>
              </div>
            </Tilt>
            <Tilt>
              <div
                class="categories-card"
                onClick={() =>
                  navigation(generatePath("Motivational Thought", "5"))
                }
              >
                <img
                  src="assets/img/category/motivational-though.webp"
                  alt="Motivational Thoughts"
                />
                <div class="text-left">
                  <h3 class="font-alt">Motivational Thoughts</h3>
                </div>
              </div>
            </Tilt>
            <Tilt>
              <div
                class="categories-card"
                onClick={() => navigation(generatePath("Leader Thoughts", "6"))}
              >
                <img
                  src="assets/img/category/Leader-Thoughts.avif"
                  alt="Leader Thoughts"
                />
                <div class="text-left">
                  <h3 class="font-alt">Leader Thoughts</h3>
                </div>
              </div>
            </Tilt>
            <Tilt>
              <div
                class="categories-card"
                onClick={() => navigation(generatePath("Devotional", "7"))}
              >
                <img
                  src="assets/img/category/devotioncal.jpg"
                  alt="Devotional"
                />
                <div class="text-left">
                  <h3 class="font-alt">Devotional</h3>
                </div>
              </div>
            </Tilt>
            <Tilt>
              <div
                class="categories-card"
                onClick={() =>
                  navigation(generatePath("Birthday Ready Made Wises", "8"))
                }
              >
                <img
                  src="assets/img/category/birthday-wish.avif"
                  alt="Birthday Ready Made Wises"
                />
                <div class="text-left">
                  <h3 class="font-alt">Birthday Ready Made Wises</h3>
                </div>
              </div>
            </Tilt>
            <Tilt>
              <div
                class="categories-card"
                onClick={() =>
                  navigation(generatePath("Birthday Thanks Wishes", "9"))
                }
              >
                <img
                  src="assets/img/category/birthday-thanku.webp"
                  alt="Birthday Thanks Wishes"
                />
                <div class="text-left">
                  <h3 class="font-alt">Birthday Thanks Wishes</h3>
                </div>
              </div>
            </Tilt>
            <Tilt>
              <div
                class="categories-card"
                onClick={() => navigation(generatePath("Good Thoughts", "10"))}
              >
                <img
                  src="assets/img/category/Good-Thoughts-.png"
                  alt="Good Thoughts ( Suvichar )"
                />
                <div class="text-left">
                  <h3 class="font-alt">Good Thoughts ( Suvichar )</h3>
                </div>
              </div>
            </Tilt>
            <Tilt>
              <div
                class="categories-card"
                onClick={() =>
                  navigation(generatePath("Aatmanirbhar Bharat", "11"))
                }
              >
                <img
                  src="assets/img/category/Aatmanirbhar Bharat.gif"
                  alt="Aatmanirbhar Bharat"
                />
                <div class="text-left">
                  <h3 class="font-alt">Aatmanirbhar Bharat</h3>
                </div>
              </div>
            </Tilt>
            <Tilt>
              <div
                class="categories-card"
                onClick={() => navigation(generatePath("Rest In Peace", "12"))}
              >
                <img
                  src="assets/img/category/Rest In Peace.avif"
                  alt="Rest In Peace"
                />
                <div class="text-left">
                  <h3 class="font-alt">Rest In Peace</h3>
                </div>
              </div>
            </Tilt>
            <Tilt>
              <div
                class="categories-card"
                onClick={() => navigation(generatePath("Sports", "13"))}
              >
                <img src="assets/img/category/Sports.avif" alt="Sports" />
                <div class="text-left">
                  <h3 class="font-alt">Sports</h3>
                </div>
              </div>
            </Tilt>
            <Tilt>
              <div
                class="categories-card"
                onClick={() =>
                  navigation(generatePath("Business Announcement", "14"))
                }
              >
                <img
                  src="assets/img/category/business-announcement.jpg"
                  alt="Business Announcement"
                />
                <div class="text-left">
                  <h3 class="font-alt">Business Announcement</h3>
                </div>
              </div>
            </Tilt>
            <Tilt>
              <div
                class="categories-card"
                onClick={() => navigation(generatePath("We Are Hiring", "15"))}
              >
                <img
                  src="assets/img/category/we-are-hiring.gif"
                  alt="We Are Hiring"
                />
                <div class="text-left">
                  <h3 class="font-alt">We Are Hiring</h3>
                </div>
              </div>
            </Tilt>
            <Tilt>
              <div
                class="categories-card"
                onClick={() => navigation(generatePath("Wishes Message", "16"))}
              >
                <img
                  src="assets/img/category/wishish.avif"
                  alt="Wishes Message"
                />
                <div class="text-left">
                  <h3 class="font-alt">Wishes Message</h3>
                </div>
              </div>
            </Tilt>
            <Tilt>
              <div
                class="categories-card"
                onClick={() => navigation(generatePath("Vande Mataram", "17"))}
              >
                <img
                  src="assets/img/category/vandematram.jpg"
                  alt="Vande Mataram"
                />
                <div class="text-left">
                  <h3 class="font-alt">Vande Mataram</h3>
                </div>
              </div>
            </Tilt>
            <Tilt>
              <div
                class="categories-card"
                onClick={() =>
                  navigation(generatePath("Bharat Mata Ki Jay", "18"))
                }
              >
                <img
                  src="assets/img/category/bharat-mata-ki-jay.avif"
                  alt="Bharat Mata Ki Jay"
                />
                <div class="text-left">
                  <h3 class="font-alt">Bharat Mata Ki Jay</h3>
                </div>
              </div>
            </Tilt>
          </div>
        </div>
      </section>

      {/* <section className="bg-slate-950 pb-28" id='pricing'>
                <Subscription />
            </section> */}

      <section
        className="h-[400px] relative border-t border-b border-slate-600"
        id="download"
        style={{
          background:
            "url(https://gamehag.com//img/rewards/background/google-play-10-usd_max.jpg)",
          backgroundSize: "cover",
        }}
      >
        <div className=" absolute top-0 left-0 right-0 bottom-0 mx-auto flex justify-center items-center bg-gradient-to-t lg:bg-gradient-to-l from-slate-900 to-black/50">
          <div className="max-w-screen-sm mx-auto text-center px-10">
            <h2 className="mb-4 text-4xl font-extrabold leading-tight tracking-tight text-gray-900 dark:text-white">
              Mobile App Coming Soon!
            </h2>
            <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">
              Unlock the power of mobile content creation with the Political
              Poster app.
            </p>
            {/* <a href="#" >
                            <img src='assets/img/google-play-badge.svg' className='max-w-40 mx-auto' />                           
                        </a> */}
          </div>
        </div>
      </section>

      <section className="" id="about-us">
        <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
          {/* Row */}
          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <Tilt>
              <img
                className="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex"
                src="assets/img/about.jpg"
                alt="dashboard feature image"
              />
            </Tilt>
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                About us
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                About DigitalPost Poster Maker App: Political Poster is founded
                by "" in year of 2024 under the Company name of Appinlay Private
                Limited our flagship product, a revolutionary app designed to
                simplify poster creation and amplify your message. Whether
                you're promoting an event, marketing a product, or sharing your
                ideas, DigitalPost empowers you to design stunning posters with
                ease.
              </p>
              {/* List */}
              <ul
                role="list"
                className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700"
              >
                <li className="flex space-x-3">
                  {/* Icon */}
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    User-Friendly Interface
                  </span>
                </li>
                <li className="flex space-x-3">
                  {/* Icon */}
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Custom Templates
                  </span>
                </li>
                <li className="flex space-x-3">
                  {/* Icon */}
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Image Library
                  </span>
                </li>
              </ul>
            </div>
          </div>
          {/* Row */}
        </div>
      </section>
    </>
  );
};

export default Home;
