import React, { useState } from "react";
import { BsFacebook, BsInstagram, BsTwitterX } from "react-icons/bs";
import { IoCameraOutline } from "react-icons/io5";
import { ImagePath } from "../../../utils/imagesPath";
import { useAppDispatch, useAppSelector } from "../../../libs/hooks/hooks";
import firebaseServices from "../../../services/firebase/fireabseApi";
import { setUserData } from "../../../libs/reducers/authentication";
import toast, { LoaderIcon } from "react-hot-toast";
import Axios from "axios";
import {
  UPLOAD_BASE_URL,
  UPLOAD_PRESET_UNSIGN,
} from "../../../services/baseurl";

const ProfileDetailsCard = () => {
  const userData = useAppSelector((state) => state.authentication.userData);
  const dispatch = useAppDispatch();
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateProfileImage = async (event) => {
    try {
      setIsProfileLoading(true);

      const image = event.target.files[0];
      const formData = new FormData();
      formData.append("file", image);
      formData.append("upload_preset", UPLOAD_PRESET_UNSIGN);
      formData.append("folder", "images");

      const response = await Axios.post(UPLOAD_BASE_URL, formData, {
        params: { secure: true },
      });

      const data = {
        imageUrl: response?.data?.secure_url,
      };

      const updatedData = await firebaseServices.updateUserData(
        userData?.id,
        data
      );

      if (!Boolean(updatedData?.error)) {
        handleGetUpdatedData(updatedData?.data);
        toast.success("Profile image updated Successfully");
      } else {
        toast.error(`Error Occurred! ${updatedData?.message}`);
      }

      setIsProfileLoading(false);
    } catch (err) {
      console.log(err);
      setIsProfileLoading(false);
      toast.error(`Error Occurred! ${err.message}`);
    }
  };
  // const imageUrl = await firebaseServices.uploadImage(image);
  // const data = {
  //   imageUrl,
  // };
  // const updatedData = await firebaseServices.updateUserData(
  //   userData?.id,
  //   data
  // );
  // if (!Boolean(updatedData?.error)) {
  //   handleGetUpdatedData(updatedData?.data);
  //   toast.success("Profile image update Successfully");
  //   setIsProfileLoading(false);
  // } else {
  //   setIsProfileLoading(false);

  //   toast.error(`Error Occured! ${updatedData?.message}`);
  // }
  const handleUpdateBusinessLogo = async (event) => {
    try {
      setIsLoading(true);

      const image = event.target.files[0];
      const formData = new FormData();
      formData.append("file", image);
      formData.append("upload_preset", UPLOAD_PRESET_UNSIGN);
      formData.append("folder", "images");

      const response = await Axios.post(UPLOAD_BASE_URL, formData, {
        params: { secure: true },
      });

      const data = {
        businesslogoUrl: response?.data?.secure_url,
      };

      const updatedData = await firebaseServices.updateUserData(
        userData?.id,
        data
      );

      if (!Boolean(updatedData?.error)) {
        handleGetUpdatedData(updatedData?.data);
        toast.success("Business logo updated Successfully");
      } else {
        toast.error(`Error Occurred! ${updatedData?.message}`);
      }

      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      toast.error(`Error Occurred! ${err.message}`);
    }
  };

  const handleGetUpdatedData = (data) => {
    dispatch(setUserData(data));
  };

  return (
    <div>
      <div className="grid grid-cols-2 self-center mb-8">
        <div className="w-28 h-28 rounded-full bg-gray-50 overflow-hidden relative mx-auto">
          <img
            src={userData?.imageUrl || ImagePath.avatar}
            alt=""
            className="w-40 h-28 object-contain"
          />
          {isProfileLoading ? (
            <div className="absolute top-0 left-0 flex justify-center items-center z-50 w-full h-full bg-slate-800 bg-opacity-45 transition-opacity cursor-pointer">
              <LoaderIcon />
            </div>
          ) : (
            <label
              htmlFor="profile-image"
              className="absolute top-0 left-0 flex justify-center items-center z-50 w-full h-full bg-slate-800 bg-opacity-45 transition-opacity cursor-pointer opacity-0 hover:opacity-100"
            >
              <IoCameraOutline color="#fff" size={30} />
            </label>
          )}

          <input
            type="file"
            id="profile-image"
            className="hidden"
            onChange={(event) => handleUpdateProfileImage(event)}
          />
        </div>
        <div className="w-28 h-28 rounded-full bg-gray-50 overflow-hidden relative mx-auto">
          <img
            src={userData?.businesslogoUrl || ImagePath.avatar}
            alt=""
            className="w-40 h-28 object-contain"
          />

          {isLoading ? (
            <div className="absolute top-0 left-0 flex justify-center items-center z-50 w-full h-full bg-slate-800 bg-opacity-45 transition-opacity cursor-pointer">
              <LoaderIcon />
            </div>
          ) : (
            <label
              htmlFor="business-logo"
              className="absolute top-0 left-0 flex justify-center items-center z-50 w-full h-full bg-slate-800 bg-opacity-45 transition-opacity cursor-pointer opacity-0 hover:opacity-100"
            >
              <IoCameraOutline color="#fff" size={30} />
            </label>
          )}
          <input
            type="file"
            id="business-logo"
            className="hidden"
            onChange={(event) => handleUpdateBusinessLogo(event)}
          />
        </div>
      </div>
      <div className="relative">
        <table className="mb-5">
          <tbody>
            <tr className="text-white">
              <td className="text-sm py-2">Name:</td>
              <td className="text-sm px-3">{userData?.name || "NA"}</td>
            </tr>
            <tr className="text-white">
              <td className="text-sm py-2">Email:</td>
              <td className="text-sm px-3">{userData?.email || "NA"}</td>
            </tr>
            <tr className="text-white">
              <td className="text-sm py-2">Phone:</td>
              <td className="text-sm px-3">{userData?.mobileNumber || "NA"}</td>
            </tr>
            <tr className="text-white">
              <td className="text-sm py-2">Whatsapp No:</td>
              <td className="text-sm px-3">
                {userData?.WhatsappNumber || "NA"}
              </td>
            </tr>
            <tr className="text-white">
              <td className="text-sm py-2">Designation:</td>
              <td className="text-sm px-3">{userData?.designation || "NA"}</td>
            </tr>
          </tbody>
        </table>

        <h3 className="text-white uppercase">Social Links</h3>
        <div className="flex justify-around py-5">
          <a
            href={userData?.FacebookUrl}
            target="_blank"
            className="text-white text-2xl"
          >
            <BsFacebook />
          </a>
          <a
            href={userData?.InstaUrl}
            target="_blank"
            className="text-white text-2xl"
          >
            <BsInstagram />
          </a>
          <a
            href={userData?.TwitterUrl}
            target="_blank"
            className="text-white text-2xl"
          >
            <BsTwitterX />
          </a>
        </div>
        <h3 className="text-white uppercase">My Party</h3>
        <div className="flex  py-5">
          <a href="" className="text-center">
            <img
              src={userData?.politicalImgUrl}
              className="w-20 h-20 rounded-full mb-2"
              alt=""
            />
            <span className="text-white text-lg">
              {userData?.politicalParty}
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetailsCard;
