import React, { useEffect, useState } from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";

import { imagesData } from "../../../utils/rawdata";

import { useAppSelector } from "../../../libs/hooks/hooks";
import PostList from "../../../components/lists/PostList";

const CategoryAllPostLayout = () => {
  const params = useParams();

  const [postType, setPostType] = useState();

  const { imageId } = useParams();
  const [image, setImage] = useState(null);
  const imagesCollection = useAppSelector(
    (state) => state.dataCollection.images
  );

  const handleGetImage = async (imageId) => {
    if (process.env.NODE_ENV === "production") {
      const image = imagesCollection.filter((item) => item.id === imageId)[0];
      //const image = await firebaseServices.getImageById(imageId);
      setImage(image);
    } else {
      const image = imagesData.filter((item) => item.id === imageId)[0];
      setImage(image);
    }
  };

  useEffect(() => {
    if (!imageId) {
      return;
    }
    handleGetImage(imageId);
  }, [imageId]);
  const category = {
    id: params?.categoryId,
    data: {
      ctgName: params?.categoryName,
    },
  };

  return (
    <div className="p-4">
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12 order-2 md:col-span-4 md:order-1">
          <div className="bg-stone-950 shadow-bg rounded-md border border-cyan-950 h-full">
            {/* <FrameList image={image} postType={postType} /> */}
            <div className="max-h-[80vh] h-full scrollbar-light">
              <PostList
                categoryItem={category}
                key={params?.categoryId}
                isHideViewAll
                isDateShow
                isHideHeading
              />
            </div>
          </div>
        </div>
        <div className="col-span-12 order-1 md:col-span-8 md:order-2">
          <div className="bg-stone-950 shadow-bg rounded-md h-full border border-cyan-950 px-0 py-3">
            <Outlet context={{ image, postType }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryAllPostLayout;
