import React from 'react'
import Button from '../../components/buttons/Button'
const data = [
    {
        id: 1,
        title: "Free",
        subHeading: "Trial",
        badge: "Starter",
        description: ["All festival post", "Daily posts", "New incident post", "Select 5 default frames", "7 downloads per day", "No post in advance"],
        price: "Free",
        validity: "Life time"
    },
    {
        id: 2,
        title: "Basic",
        subHeading: "Fast Start",
        badge: "Value",
        description: ["All festival post", "Daily posts", "New incident post", "Select 10 default frames (can change any time)", "Can Download 7 post without watermark per day", "No downloads limit with watermark", "3 days post in advance", "profile setup"],
        price: "₹599",
        validity: "1 Year"
    },
    {
        id: 3,
        title: "Premium",
        subHeading: "Accelerate",
        badge: "Pro",
        description: ["All festival post", "Daily posts", "New incident post", "All default frame", "No limit for downloading without watermark", "All post in advance", "5 custom frames", "Profile setup"],
        price: "₹699",
        validity: "1 Year"
    },
]
const SubscriptionCard = ({ data }) => {
    return  <div key={3} className="w-full bg-stone-950 rounded-[10px]  border border-cyan-950  divide-y divide-cyan-950 flex flex-col relative overflow-hidden">
        <div className="pt-[15px] px-[25px] pb-[20px] shrink ">
            <div className="flex justify-end">
                <div className="bg-cyan-950 rounded-[20px] flex justify-center align-center px-[12px]">
                    <p className="text-[#ddd] text-[12px] leading-[28px] font-bold">
                        {data?.badge}
                    </p>
                </div>
            </div>
            <div>
                <p className="text-[#e1e1e1] text-[19px] leading-[24px] font-bold">
                    {data?.subHeading}
                </p>
                <p className="text-[#fff] text-[50px] leading-[63px] font-bold">
                    {data?.price}
                </p>
            </div>
            <div>
                <p className="text-[#C2C2C2] text-[18px] leading-[28px] font-medium">
                    {data?.validity}
                </p>
            </div>
        </div>
        <div className="pt-[20px] px-[25px] pb-[25px] flex flex-col grow">
            <div className='grow'>
                <ul>
                    {data?.description?.map((i, j) => {
                        return <li key={j} className="text-[#717F87] text-[14px] leading-[24px] font-medium">
                            {i}
                        </li>
                    })}
                </ul>
            </div>
            <div className="mt-[25px]">
                <Button label={"Choose Plan"}/>
            </div>
        </div>
        {/* <div className='absolute top-0 left-0 right-0 bottom-0 bg-white/20'>
            <div className='-rotate-[65deg] translate-x-[90px] absolute bottom-0 top-0 left-0 flex justify-center items-center'>
                <h2 className='text-7xl uppercase text-nowrap text-green-500'>Free </h2>
            </div>
        </div> */}
    </div>
}



const Subscription = () => {
    return (
        <div className='max-w-7xl mx-auto'>
            <div className="flex min-h-[80vh] pt-[30px] px-[40px] items-center">
                <div className="min-w-full">
                    <p className="text-[#fff] text-[20px] leading-[40px] font-semibold">
                        Pricing
                    </p>
                    <div className="mt-[20px] grid lg:grid-cols-3 gap-[20px]">
                        {data?.map((item, index) => {
                            return <SubscriptionCard key={index} data={item}
                            />
                        })}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Subscription