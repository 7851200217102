import React, { useEffect, useRef, useState } from "react";
import { LuSearch } from "react-icons/lu";
import { FaChevronLeft } from "react-icons/fa6";
import { useAppSelector } from "../../libs/hooks/hooks";
import PostList from "../lists/PostList";
function Search({ isVisible, onClose }) {
  const searchRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    function handleClickOutside(event) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchQuery("");
        setFilterData([]);

        onClose(); // Close the search component when clicking outside
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Unbind the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const categoriesImage = useAppSelector(
    (state) => state.dataCollection.imagesCategories
  );
  const categoriesVideo = useAppSelector(
    (state) => state.dataCollection.videosCategories
  );

  const [filtedata, setFilterData] = useState([]);
  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    const filteredCategories = categoriesImage.filter((category) =>
      category.data.ctgName.toLowerCase().includes(value.toLowerCase())
    );
    setFilterData(filteredCategories);
  };

  return (
    isVisible && (
      <>
        <div className="fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-50">
          <div
            className="overflow-hidden relative max-w-full  bg-white md:h-[70vh] h-[100vh] w-[840px]  rounded"
            ref={searchRef}
          >
            <div className="w-full h-full">
              <div className="flex-grow-1/2 flex p-2 flex-col">
                <div className="flex pb-2">
                  <button className="p-1 md:hidden" onClick={onClose}>
                    <FaChevronLeft size={17} />
                  </button>
                  <div className="bg-white p-2 flex items-center gap-12 border border-gray-300 rounded w-full">
                    <LuSearch size={18} color={"#A67C4A"} />
                    <input
                      placeholder="Search"
                      className="w-full"
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                <div
                  className="pt-3 flex-grow h-screen overflow-auto"
                  style={{ height: "calc(100vh - 80px)" }}
                >
                  {filtedata.slice(0, 9)?.map((category, index) => {
                    return (
                      <PostList
                        categoryItem={category}
                        key={category?.categoryId}
                        isHideViewAll
                        isDateShow
                        isHideHeading
                        searchQuery
                        filtedata
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default Search;
