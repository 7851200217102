import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

const PageTitle = ({ title }) => {
  const location = useLocation();
  const params = useParams();

  const paramsTitle = params.categoryName && params?.categoryName[0].toUpperCase() + params?.categoryName.slice(1, undefined) + " " + title
  useEffect(() => {
    document.title = paramsTitle || title;
  }, [location, title]);

  return null; // This component doesn't render anything
};

export default PageTitle;
