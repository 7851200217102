import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";

const LobbyLayout = () => {
  return (
    <div className="w-full h-screen flex flex-col">
      <Header />
      <div
        className="flex-1 lg:pt-20 pt-14 bg-neutral-900 max-w-screen pb-20 bg-cover bg-no-repeat "
        style={{
          backgroundImage:
            "url(https://img.freepik.com/free-vector/dark-color-pattern-abstract-style_1035-2691.jpg?w=740&t=st=1714042515~exp=1714043115~hmac=57bca228b3d3662c21bca0ea74c5f357575e8e176da89146cfb9613326b19aec)",
        }}
      >
        <div className="mx-auto">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LobbyLayout;
