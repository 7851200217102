import { useState } from "react";
import { FiUser } from "react-icons/fi";
import { IoShareSocialOutline } from "react-icons/io5";
import { MdSecurity } from "react-icons/md";
import Input from "../../../components/inputs/Input";
import Button from "../../../components/buttons/Button";
import { useAppDispatch, useAppSelector } from "../../../libs/hooks/hooks";
import firebaseServices from "../../../services/firebase/fireabseApi";
import { useForm } from "react-hook-form";
import toast, { LoaderIcon } from "react-hot-toast";
import { setUserData } from "../../../libs/reducers/authentication";
const tabs = ["profile", "social", "security"];
const Tabs = ({ onTabChange, activeTab }) => {
  const activeClass = "text-white border-white";
  const tabClass =
    "inline-flex w-full items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg group hover:text-white";
  return (
    <div className="border-b border-gray-200 dark:border-gray-700 w-full">
      <ul className="flex items-center -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
        <li className="w-full" onClick={() => onTabChange(tabs[0])}>
          <a
            href="#"
            className={`${tabClass} ${activeTab === tabs[0] && activeClass}`}
          >
            <FiUser size={20} />
            &nbsp; Profile
          </a>
        </li>
        <li className="w-full" onClick={() => onTabChange(tabs[1])}>
          <a
            href="#"
            className={`${tabClass} ${activeTab === tabs[1] && activeClass}`}
            aria-current="page"
          >
            <IoShareSocialOutline size={20} />
            &nbsp;Social Links
          </a>
        </li>
        <li className="w-full" onClick={() => onTabChange(tabs[2])}>
          <a
            href="#"
            className={`${tabClass} ${activeTab === tabs[2] && activeClass}`}
          >
            <MdSecurity size={20} />
            &nbsp;Security
          </a>
        </li>
      </ul>
    </div>
  );
};

const Profile = () => {
  const userData = useAppSelector((state) => state.authentication.userData);
  const politicalPartyLogo = useAppSelector(
    (state) => state.dataCollection.politicalPartyLogo
  );
  const politicalPartyName = useAppSelector(
    (state) => state.dataCollection.politicalPartyName
  );
  const [activeTab, setActiveTab] = useState("profile");
  const [selectedLogo, setSelectedLogo] = useState({ id: "", url: "" });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateProfile = async (values) => {
    setIsLoading(true);
    const data = {
      name: values?.name,
      designation: values?.designation,
      mobileNumber: values?.mobileNumber,
      WhatsappNumber: values?.WhatsappNumber,
    };

    const updatedData = await firebaseServices.updateUserData(
      userData?.id,
      data
    );

    if (!Boolean(updatedData?.error)) {
      setIsLoading(false);

      toast.success("Profile Updated Successfully");
      handleGetUpdatedData(updatedData?.data);
    } else {
      setIsLoading(false);

      toast.error(`Error Occured! ${updatedData?.message}`);
    }
  };

  const handleUpdateSocialLinks = async (values) => {
    setIsLoading(true);

    const data = {
      InstaUrl: values?.InstaUrl,
      FacebookUrl: values?.FacebookUrl,
      TwitterUrl: values?.TwitterUrl,
      politicalImgUrl: selectedLogo?.url,
      politicalParty: values?.politicalParty,
    };

    const updatedData = await firebaseServices.updateUserData(
      userData?.id,
      data
    );

    if (!updatedData?.error) {
      setIsLoading(false);

      toast.success("Social Links Updated Successfully");
      handleGetUpdatedData(updatedData?.data);
    } else {
      setIsLoading(false);

      toast.error(`Error Occured! ${updatedData.error.message}`);
    }
  };

  const handleUpdatePassword = async (values) => {
    setIsLoading(true);

    if (userData?.password !== values?.oldPassword) {
      toast.error("Old Password is incorrect");
      return;
    }
    if (values?.password !== values?.confirmPassword) {
      toast.error("Confirm passwords do not match");
      return;
    }

    const data = {
      password: values?.password,
    };

    const updatedData = await firebaseServices.updateUserData(
      userData?.id,
      data
    );

    if (!updatedData?.error) {
      setIsLoading(false);

      toast.success("Password Updated Successfully");
      handleGetUpdatedData(updatedData?.data);
    } else {
      setIsLoading(false);

      toast.error(`Error Occured! ${updatedData.error.message}`);
    }
  };

  const handleGetUpdatedData = async (data) => {
    dispatch(setUserData(data));
  };

  return (
    <div className="max-w-full h-[80vh] overflow-auto">
      <Tabs onTabChange={setActiveTab} activeTab={activeTab} />
      {activeTab === tabs[0] && (
        <form
          className="px-5 py-3"
          onSubmit={handleSubmit(handleUpdateProfile)}
        >
          <h2 className="text-white mb-5 text-2xl font-semibold">
            Update Profile
          </h2>
          <div className="mb-5">
            <Input
              label={"Name"}
              value={userData?.name}
              inputClass={"bg-gray-950 text-white"}
              isError={errors.name}
              inputProps={{
                ...register("name", { required: "Name is required" }),
              }}
            />
          </div>
          <div className="mb-5">
            <Input
              label={"Email"}
              value={userData?.email}
              inputClass={"bg-gray-950 text-white"}
              inputProps={{
                disabled: true,
              }}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-5">
            <div className="mb-5">
              <Input
                label={"Mobile number"}
                value={userData?.mobileNumber}
                inputClass={"bg-gray-950 text-white"}
                inputProps={{ ...register("mobileNumber") }}
              />
            </div>
            <div className="mb-5">
              <Input
                label={"Whatsapp number"}
                value={userData?.WhatsappNumber}
                inputClass={"bg-gray-950 text-white"}
                inputProps={{ ...register("WhatsappNumber") }}
              />
            </div>
          </div>
          <div className="mb-5">
            <Input
              label={"Designation"}
              value={userData?.designation}
              inputClass={"bg-gray-950 text-white"}
              inputProps={{ ...register("designation") }}
            />
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center">
              <LoaderIcon size={25} />
            </div>
          ) : (
            <Button label={"Update Profile"} isDisable={isSubmitting} />
          )}
        </form>
      )}
      {activeTab === tabs[1] && (
        <form
          className="px-5 py-3"
          onSubmit={handleSubmit(handleUpdateSocialLinks)}
        >
          <h2 className="text-white mb-5 text-2xl font-semibold">
            Update Social Links
          </h2>
          <div className="mb-5">
            <Input
              label={"Facebook"}
              value={userData?.FacebookUrl}
              inputClass={"bg-gray-950 text-white"}
              inputProps={{ ...register("FacebookUrl") }}
            />
          </div>
          <div className="mb-5">
            <Input
              label={"Instagram"}
              value={userData?.InstaUrl}
              inputClass={"bg-gray-950 text-white"}
              inputProps={{ ...register("InstaUrl") }}
            />
          </div>
          <div className="mb-5">
            <Input
              label={"Twitter"}
              value={userData?.TwitterUrl}
              inputClass={"bg-gray-950 text-white"}
              inputProps={{ ...register("TwitterUrl") }}
            />
          </div>

          <div className="mb-5">
            <label className="block mb-2 text-sm font-medium text-white">
              Political Party
            </label>
            <select
              {...register("politicalParty")}
              className="block w-full p-2.5 bg-gray-950 text-white border rounded-md border-gray-600 placeholder-gray-400 text-sm focus:ring-slate-500 focus:border-slate-500"
            >
              <option>None</option>
              {politicalPartyName?.map((item, index) => {
                return <option key={item?.id}>{item?.data?.party_name}</option>;
              })}
            </select>
          </div>

          <div className="mb-5">
            <label className="block mb-2 text-sm font-medium text-white">
              Political Logo
            </label>
            <div className="flex items-center gap-2 flex-wrap">
              {politicalPartyLogo?.map((item, index) => {
                return (
                  <button
                    key={item.id}
                    htmlFor={"logoImage"}
                    onClick={() =>
                      setSelectedLogo({ id: item?.id, url: item?.data?.url })
                    }
                    type="button"
                    className={`border-2  rounded-full p-1 ${
                      item?.id === selectedLogo.id
                        ? " border-orange-400"
                        : "border-transparent"
                    }`}
                  >
                    <img
                      className="w-14 h-14 rounded-full"
                      src={item?.data?.url}
                    />
                  </button>
                );
              })}
            </div>
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center">
              <LoaderIcon size={25} />
            </div>
          ) : (
            <Button label={"Update"} isDisable={isSubmitting} />
          )}
        </form>
      )}
      {activeTab === tabs[2] && (
        <form
          className="px-5 py-3"
          onSubmit={handleSubmit(handleUpdatePassword)}
        >
          <h2 className="text-white text-2xl font-semibold mb-5">
            Update Password
          </h2>
          <div className="mb-5">
            <Input
              type={"password"}
              eyeColor="#fff"
              label={"Old password"}
              inputClass={"bg-gray-950 text-white"}
              isError={errors?.oldPassword}
              inputProps={{
                ...register("oldPassword", {
                  required: "Old Password is required",
                }),
              }}
            />
          </div>
          <div className="mb-5">
            <Input
              type={"password"}
              eyeColor="#fff"
              label={"New password"}
              inputClass={"bg-gray-950 text-white"}
              isError={errors?.password}
              inputProps={{
                ...register("password", {
                  required: "Password is required",
                  pattern: {
                    value:
                      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
                    message:
                      "Password should contain atleast one number and one special character",
                  },
                  minLength: {
                    value: 8,
                    message: "Password min length is 8",
                  },
                }),
              }}
            />
          </div>
          <div className="mb-5">
            <Input
              type={"password"}
              eyeColor="#fff"
              label={"Confirm password"}
              inputClass={"bg-gray-950 text-white"}
              isError={errors?.confirmPassword}
              inputProps={{
                ...register("confirmPassword", {
                  required: "Confirm password is required",
                }),
              }}
            />
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center">
              <LoaderIcon size={25} />
            </div>
          ) : (
            <Button label={"Update Password"} isDisable={isSubmitting} />
          )}
        </form>
      )}
    </div>
  );
};

export default Profile;
