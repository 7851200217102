import React from "react";
import { ImagePath } from "../../utils/imagesPath";

const Footer = () => {
  return (
    <footer className="p-5 bg-slate-950 flex items-center">
      <h3 className="text-white">Political Post By Appinlay</h3>
      {/* <a
        href="https://play.google.com/store/apps/details?id=com.nupost.app"
        target="_blank"
      >
        <img src={ImagePath.appLink} alt="AppLink" height={160} width={160} />
      </a> */}
    </footer>
  );
};

export default Footer;
