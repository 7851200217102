"use client"
import React, { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../libs/hooks/hooks';
import firebaseServices from '../../services/firebase/fireabseApi';
import Tilt from "react-parallax-tilt";

import {
    setImages,
    setImagesCategories,
    setVideos,
    setVideosCategories,
} from "../../libs/reducers/datacollection";
import {
    categoriesData,
    imagesData,
    videoCategoriesData,
    videosData,
} from "../../utils/rawdata";
import { useNavigate } from 'react-router-dom';
const CategoriesSection = ({ children }) => {

    const dispatch = useAppDispatch();
    const navigation = useNavigate();
    const images = useAppSelector((state) => state.dataCollection.images);

    const categoriesImage = useAppSelector(
        (state) => state.dataCollection.imagesCategories
    );

    const generatePath = (categoryname, id) => {
        const base = `/allcategoryitems/${categoryname}/${id}`;
        window.scrollTo(0, 0);
        return base;
    };

    const handleGetImages = useCallback(async () => {
        const imagesCategories = await firebaseServices.getDataCollections(
            "categories"
        );
        const images = await firebaseServices.getDataCollections("images");
        const videos = await firebaseServices.getDataCollections("videos");
        const videosCategories = await firebaseServices.getDataCollections(
            "videocategories"
        );

        if (process.env.NODE_ENV === "production") {
            if (imagesCategories.length > 0) {
                dispatch(setImagesCategories(imagesCategories));
            }

            if (images.length > 0) {
                dispatch(setImages(images));
            }

            if (videos.length > 0) {
                dispatch(setVideos(videos));
            }

            if (videosCategories.length > 0) {
                dispatch(setVideosCategories(videosCategories));
            }
        } else {
            dispatch(setImagesCategories(categoriesData));
            dispatch(setImages(imagesData));
            dispatch(setVideos(videosData));
            dispatch(setVideosCategories(videoCategoriesData));
        }
    }, []);
    useEffect(() => {
        handleGetImages();

        return () => {

        }
    }, []);

    return (
            categoriesImage.map((item) => (
                <Tilt key={item?.id}>
                    <div
                        class="categories-card cursor-pointer"
                        onClick={() =>
                            navigation(generatePath(item?.data?.ctgName, item.id))
                        }
                    >
                        {images.find((data) => item.id === data?.data?.ctgIds)
                            ?.data && (
                                <img
                                    src={
                                        images.find((data) => item.id === data?.data?.ctgIds)
                                            ?.data?.url
                                    }
                                    alt={item?.data?.ctgName}
                                />
                            )}
                        <div class="text-left">
                            <h3 class="font-alt">{item?.data?.ctgName}</h3>
                        </div>
                    </div>
                </Tilt>
            ))
        
    )
}

export default CategoriesSection