import React from 'react'
import Input from '../../../components/inputs/Input'
import Button from '../../../components/buttons/Button'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'

const ForgotPassword = () => {
  const { register, handleSubmit, watch, formState: { errors }, } = useForm();
  const onSubmit = (values) => {

  }
  return (
    <div className='h-full flex justify-center items-center'>
      <form className="max-w-md mx-auto w-[440px] px-5" onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-10'>
          <h3 className='text-white text-4xl font-semibold text-center'>Forgot Password</h3>
        </div>      
        <div className="mb-5">
          <Input type={'text'} placeholder={"John deo"} label={"Your name"}
            isError={errors.name}
            inputProps={{ ...register("name", { required: "Name is required", }) }}/>
        </div>
        <div className="mb-5">
          <Input type={'email'} placeholder={"example@email.com"} label={"Your email"}
            isError={errors.email}
            inputProps={{ ...register("email", { required: "Email is required", }) }}/>
        </div>
        <div className="mb-10">
          <Input type={'text'} placeholder={"9876543210"} label={"Your mobile"}
            isError={errors.phone}
            inputProps={{ ...register("phone", { required: "Mobile number is required", }) }}/>
        </div>
        <Button label="Submit" type={'submit'}  />
        <div className='pt-10'>
          <p className='text-white text-center text-sm'>Remember password? <Link to={'/auth/register'} className='text-blue-500'>Login here</Link></p>
        </div>
      </form>
    </div>
  )
}

export default ForgotPassword