import React, { useRef, useState } from "react";
import { FaPlay, FaPause } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
const PostCard = ({ data, isVideo, grid, categoryName, catdata }) => {
  const videoRef = useRef(null);
  const [isVideoPlay, setVideoPlay] = useState(false);
  const pathname = useLocation();

  const handlePlayPauseVideo = () => {
    setVideoPlay((prev) => {
      if (prev) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      return !prev;
    });
  };
  const navigation = useNavigate();

  const handleNavigation = () => {
    const path = `/dashboard/create-post/${data?.id}`;
    if (pathname.pathname === path) {
      return;
    } else {
      navigation(path);
    }
  };

  const generatepath = (categoryId, postId) => {
    const path = `/dashboard/allpost/${categoryId}/${postId}`;
    return path;
  };
  if (isVideo) {
    return (
      <div className="w-32 h-32 md:w-60 md:h-60 rounded-md overflow-hidden shadow relative group">
        <video className="h-full w-full object-cover" ref={videoRef}>
          <source src={data?.data?.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          className="transition-opacity opacity-0 group-hover:opacity-100 absolute top-0 left-0 z-0  w-full h-full flex items-center justify-center bg-slate-600 bg-opacity-30 cursor-pointer"
          onClick={handlePlayPauseVideo}
        >
          {isVideoPlay ? (
            <FaPause size={50} color="#fff" />
          ) : (
            <FaPlay size={50} color="#fff" />
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      // onClick={handleNavigation}
      onClick={() => navigation(generatepath(catdata?.id, data?.id))}
      className={`${
        grid ? "w-full h-full" : "w-32 h-32"
      } md:w-60 md:h-60 rounded-md overflow-hidden shadow relative cursor-pointer group`}
    >
      <img
        src={data?.data?.url}
        alt=""
        loading="lazy"
        className="transition-transform h-full w-full object-cover group-hover:scale-110"
      />
      {categoryName === "upcoming posts" && (
        <div className="text-white text-xs  absolute bottom-2 left-2 bg-blue-500 p-1 rounded">
          {data?.data.date}
        </div>
      )}
    </div>
  );
};

export default PostCard;
