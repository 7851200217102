import { Outlet } from "react-router-dom";
import ProfileDetailsCard from "./ProfileDetailsCard";
import Button from "../../../components/buttons/Button";
import { useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
const ProfileLayout = () => {
  const [isProfileUpdate, setProfileUpdate] = useState(false);
  return (
    <div className="max-w-7xl mx-auto mt-5 px-5">
      <div className="grid md:grid-cols-12 gap-5">
        <div className="col-span-12 md:col-span-4">
          <div className="bg-stone-950 border border-cyan-950 p-5 shadow-bg rounded-md md:mb-0 mb-4">
            <ProfileDetailsCard />
          </div>
          <Button
            className={"md:hidden"}
            label={"Update Profile"}
            onClick={() => setProfileUpdate(!isProfileUpdate)}
          />
        </div>
        <div
          className={`col-span-12 md:col-span-8 md:block
                 ${!isProfileUpdate && "hidden"}`}
        >
          <div className="bg-stone-950 border border-cyan-950 p-5 pt-0 px-0 shadow-bg rounded-md overflow-hidden md:relative fixed md:top-0 top-14 w-full left-0 h-full z-[99] md:z-0">
            <button
              type="button"
              className="flex items-center py-3 px-5 md:hidden"
              onClick={() => setProfileUpdate(!isProfileUpdate)}
            >
              <span className="bg-white p-2 rounded-full">
                <FaArrowLeft />
              </span>
            </button>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileLayout;
