import React, { useState } from 'react'
import { HiMiniEye, HiMiniEyeSlash } from "react-icons/hi2";
const Input = ({ placeholder, value, onChange, label, inputClass, labelClass, type, htmlId, name, required, inputProps, isError, eyeColor = "#000" }) => {
    const [isPasswordVisible, setPasswordVisible] = useState(false)
    const handlePasswordVisibility = () => {
        setPasswordVisible(!isPasswordVisible)
    }
    const inputType = type === 'password' ? isPasswordVisible ? 'text' : type : type;
    return (
        <>
            <label htmlFor={htmlId} className={`block mb-2 text-sm font-medium text-white ${labelClass}`}>{label}</label>
            <div className='w-full relative'>
                <input
                    type={inputType}
                    id={htmlId}
                    name={name}
                    required={required}
                    defaultValue={value}
                    placeholder={placeholder}
                    onChange={onChange && onChange}
                    className={`block w-full p-2.5 bg-gray-100 border rounded-md border-gray-600 placeholder-gray-400 text-black text-sm focus:ring-slate-500 focus:border-slate-500 ${inputClass} ${inputProps?.disabled && "opacity-45"}`}
                    {...inputProps}

                />
                {type === 'password' && <button className='absolute top-3 right-4' type='button' onClick={handlePasswordVisibility}>{isPasswordVisible ? <HiMiniEye color={eyeColor} size={18} /> : <HiMiniEyeSlash color={eyeColor} size={18} />}</button>}
            </div>
            {isError && <small className='text-red-500 text-sm'>{isError?.message}</small>}
        </>
    )
}

export default Input