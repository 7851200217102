// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getDatabase } from "firebase/database";
let firebaseConfig;
// if (process.env.NODE_ENV === "development") {
//   firebaseConfig = {
//     apiKey: "AIzaSyCQsoDuOlP_7ekG50qcrV46XxXmskrs2OM",
//     authDomain: "invoice-fce03.firebaseapp.com",
//     projectId: "invoice-fce03",
//     storageBucket: "invoice-fce03.appspot.com",
//     messagingSenderId: "931469499003",
//     appId: "1:931469499003:web:6b8a4648a59be0b2a5e0de",
//     measurementId: "G-HJM8Z0J3BP",
//   };
// } else if (process.env.NODE_ENV === "production") {
//   firebaseConfig = {
//     apiKey: "AIzaSyD903liLVSY6P4jGmLwfvMFo5bJ-s43_Xk",
//     authDomain: "nupost-app.firebaseapp.com",
//     projectId: "nupost-app",
//     storageBucket: "nupost-app.appspot.com",
//     messagingSenderId: "1055147137413",
//     appId: "1:1055147137413:web:6cf7db3f825dbbbc2e4cec",
//     measurementId: "G-2YSLDM7WHP",
//   };
// }
firebaseConfig = {
  apiKey: "AIzaSyD903liLVSY6P4jGmLwfvMFo5bJ-s43_Xk",
  authDomain: "nupost-app.firebaseapp.com",
  projectId: "nupost-app",
  storageBucket: "nupost-app.appspot.com",
  messagingSenderId: "1055147137413",
  appId: "1:1055147137413:web:6cf7db3f825dbbbc2e4cec",
  measurementId: "G-2YSLDM7WHP",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const fireStore = getFirestore(app);
export const storage = getStorage(app);
export const database = getDatabase(app);

export { ref, uploadBytes, getDownloadURL };
