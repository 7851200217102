import React, { useCallback, useEffect, useState } from "react";
import { RiDownloadLine, RiShareBoxLine } from "react-icons/ri";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { Frames } from "../../../utils/vectors/frames/Frames";
import { useAppSelector } from "../../../libs/hooks/hooks";
import html2canvas from "html2canvas";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import { useRef } from "react";
import ShareModal from "../../../components/shared/ShareModal";
import toast, { LoaderIcon } from "react-hot-toast";
import { PoliticalFrames } from "../../../utils/vectors/frames/PoliticalFrames";
import { ModalLayout } from "../../../components/modal/ModalLayout";
import { FormattedDate } from "../../../utils/FormattedDate";
import { ImagePath } from "../../../utils/imagesPath";

const CreatePost = () => {
  const userData = useAppSelector((state) => state.authentication.userData);
  const { image, postType } = useOutletContext();
  const [dataurl, setDataUrl] = useState("");
  const [isUpcomingModal, setIsUpcomingModal] = useState(false);
  const [isPoliticalModal, setIsPoliticalModal] = useState(false);
  const { postFrameId } = useAppSelector((state) => state.createPost);
  const frameImage = () => {
    if (postType === "posType=political") {
      const frame = PoliticalFrames.filter(
        (item) => item.id == postFrameId
      )[0].image("100%", "100%", userData, userData?.imageUrl);
      return frame;
    } else {
      const frame = Frames.filter((item) => item.id == postFrameId)[0].image(
        "100%",
        "100%",
        userData,
        userData?.imageUrl
      );
      return frame;
    }
  };
  const imageRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {

  //   if (userData && userData.imageUrl) {

  //   }
  // }, [userData]);

  //   const handleDownloadButton = () => {
  //   if (FormattedDate() < image?.data.date) {
  //     setIsUpcomingModal(true);
  //     return;
  //   }
  //   setIsLoading(true);

  //   if (imageRef.current === null) {
  //     setIsLoading(false);
  //     return;
  //   }

  //   htmlToImage.toPng(imageRef.current)
  //     .then((dataUrl) => {
  //       const link = document.createElement("a");
  //       link.download =
  //         (userData?.name || "image") +
  //         "-" +
  //         postType +
  //         new Date().toLocaleTimeString() +
  //         ".png";

  //       link.href = dataUrl;
  //       link.click();
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       console.error("Error creating image:", error);
  //     });
  // };

  const handleDownloadButton = () => {
    if (FormattedDate() < image?.data.date) {
      setIsUpcomingModal(true);
      return;
    }

    setIsLoading(true);

    if (imageRef.current === null) {
      return;
    }
    try {
      toPng(imageRef.current, {
        cacheBust: true,
        canvasHeight: 500,
        canvasWidth: 500,
        pixelRatio: 4,
        type: "image/png",
      })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download =
            userData?.name +
            "-" +
            postType +
            new Date().toLocaleTimeString() +
            ".png";
          link.href = dataUrl;
          link.click();
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }

    // html2canvas(imageRef.current, { allowTaint: false, useCORS: true, imageTimeout:0 }).then((canvas) => {
    //   const link = document.createElement("a");
    //   link.download = userData?.name + "-" + postType + new Date().toLocaleTimeString() + ".png";
    //   link.href = canvas.toDataURL().replace("image/png", "image/octet-stream");
    //   link.click();
    //   setIsLoading(false);
    // }).catch((error) => {
    //     setIsLoading(false);
    //     console.error("Error creating canvas:", error);
    //   });
  };

  return (
    <>
      <div className="max-h-[87vh] h-full scrollbar-light">
        <div className="w-full h-full flex items-center justify-evenly flex-col">
          <div className="lg:w-2/5 px-3 mb-5">
            <div
              className="bg-cover cursor-pointer relative aspect-square"
              id="htmltoimage"
              ref={imageRef}
            >
              <>
                <div className="absolute w-10 h-10 top-3 left-3 rounded-full">
                  <img
                    className="rounded-full"
                    src={
                      postType === "posType=political"
                        ? userData?.politicalImgUrl || ImagePath.avatar
                        : userData?.businesslogoUrl || ImagePath.avatar
                    }
                    alt=""
                  />
                </div>
                <img
                  src={image?.data?.url}
                  className="w-full h-full object-cover -z-10 relative"
                />

                <div className="absolute z-10 w-full h-full left-0 right-0 bottom-0">
                  {frameImage()}
                </div>
              </>
            </div>
          </div>
          <div className="flex gap-3 mb-3">
            <button
              onClick={handleDownloadButton}
              className="bg-white transition-colors hover:bg-slate-200 px-3 py-2 rounded text-sm flex items-center gap-3"
            >
              {isLoading ? <LoaderIcon size={20} /> : <RiDownloadLine />}
              Download
            </button>
            {/* <button className="bg-white transition-colors hover:bg-slate-200 px-6 py-2 rounded text-sm flex items-center gap-3">
              <RiShareBoxLine />
              Share
            </button> */}
          </div>
        </div>
      </div>
      <ModalLayout
        isVisible={isUpcomingModal}
        title={""}
        dialogClass={"max-w-[25rem]"}
        onClose={() => {
          setIsUpcomingModal(false);
        }}
      >
        <div className="flex items-center justify-center text-white gap-5">
          <span>Wait For Upcoming Date!</span>
        </div>
      </ModalLayout>

      {/* <ShareModal /> */}
    </>
  );
};

export default CreatePost;
