import { Link, useNavigate } from "react-router-dom";
import { ImagePath } from "../../utils/imagesPath";
import { AiOutlineQuestionCircle, AiOutlineSearch } from "react-icons/ai";
import { FiUser } from "react-icons/fi";
import { IoMdLogOut } from "react-icons/io";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../libs/hooks/hooks";
import firebaseServices from "../../services/firebase/fireabseApi";
import {
  setImages,
  setImagesCategories,
  setPoliticalPartyLogo,
  setPoliticalPartyName,
  setVideos,
  setVideosCategories,
} from "../../libs/reducers/datacollection";
import {
  categoriesData,
  imagesData,
  videoCategoriesData,
  videosData,
  politicalPartyLogo as _politicalPartyLogo,
  politicalPartyName as _politicalPartyName,
} from "../../utils/rawdata";
import { setLoggedIn } from "../../libs/reducers/authentication";
import Search from "../search/Search";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import ContactInfoModal from "../modal/ContactInfoModal";

const Header = () => {
  const userData = useAppSelector((state) => state.authentication.userData);
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [isSupportVisible, setIsSupportVisible] = useState(false);
  const handelSupport = () => {
    setIsSupportVisible(!isSupportVisible);
  };
  const handleSearch = () => {
    setIsVisible(!isVisible);
  };
  const handleGetImages = useCallback(async () => {
    const imagesCategories = await firebaseServices.getDataCollections(
      "categories"
    );
    const images = await firebaseServices.getDataCollections("images");
    const videos = await firebaseServices.getDataCollections("videos");
    const videosCategories = await firebaseServices.getDataCollections(
      "videocategories"
    );

    if (process.env.NODE_ENV === "production") {
      if (imagesCategories.length > 0) {
        dispatch(setImagesCategories(imagesCategories));
      }

      if (images.length > 0) {
        dispatch(setImages(images));
      }

      if (videos.length > 0) {
        dispatch(setVideos(videos));
      }

      if (videosCategories.length > 0) {
        dispatch(setVideosCategories(videosCategories));
      }
    } else {
      dispatch(setImagesCategories(categoriesData));
      dispatch(setImages(imagesData));
      dispatch(setVideos(videosData));
      dispatch(setVideosCategories(videoCategoriesData));
    }
  }, []);

  const handleGetPoliticalParty = useCallback(async () => {
    const politicalPartyLogo = await firebaseServices.getDataCollections(
      "partylogo"
    );
    const politicalPartyName = await firebaseServices.getDataCollections(
      "politicalpartys"
    );
    if (process.env.NODE_ENV === "production") {
      if (politicalPartyLogo?.length > 0) {
        dispatch(setPoliticalPartyLogo(politicalPartyLogo));
      }
      if (politicalPartyName?.length) {
        dispatch(setPoliticalPartyName(politicalPartyName));
      }
    } else {
      dispatch(setPoliticalPartyName(_politicalPartyName));
      dispatch(setPoliticalPartyLogo(_politicalPartyLogo));
    }
  }, []);

  useEffect(() => {
    handleGetImages();
    handleGetPoliticalParty();
    return () => {};
  }, []);

  const handleLogout = () => {
    dispatch(setLoggedIn(false));
    localStorage.clear();
    navigation("/auth/login");
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <header className="flex items-center justify-between bg-neutral-950 px-2 lg:px-8 lg:py-3 py-1 fixed w-full z-10">
      {/* User image */}
      <div className="flex-0">
        <div className="flex items-end flex-col">
          <div className="flex flex-col items-center group">
            <div className="relative group">
              <div
                className="Profile-img w-8 h-8 md:w-12 md:h-12 rounded-full overflow-hidden bg-slate-400 cursor-pointer"
                onClick={handleDropdownToggle}
              >
                <img
                  className="w-full h-full object-cover"
                  src={userData?.imageUrl || ImagePath.avatar}
                  alt="Profile"
                />
              </div>
              {isDropdownOpen && (
                <div className="dropdown hidden group-hover:block absolute top-full left-0  bg-white rounded-md shadow-lg overflow-hidden">
                  <ul>
                    {/* <li className="px-4 py-2 border-b">
                      <p className="text-sm font-semibold">{userData?.name}</p>
                      <small className="text-xs font-semibold">
                        Plan:{" "}
                        <span className="bg-[#007FFF] text-white px-2 text-[12px] py-0.5 rounded-md font-light capitalize">
                          {userData?.userType}
                        </span>
                      </small>
                    </li> */}
                    <li
                      className="px-4 py-2 border-b flex items-center gap-2 hover:bg-slate-300 transition-colors"
                      onClick={handleDropdownToggle}
                    >
                      <FiUser />
                      <Link to={"/dashboard/profile"} className="text-sm">
                        Profile
                      </Link>
                    </li>
                    <li
                      onClick={handleLogout}
                      className="px-4 py-2 flex items-center gap-2 hover:bg-slate-300 transition-colors"
                    >
                      <IoMdLogOut />
                      <Link className="text-sm">Logout</Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* How to use */}
      <div className="flex-auto ">
        <a href="#" className="flex flex-col px-3 items-center">
          <div>
            <AiOutlineQuestionCircle color="#fff" size={24} />
          </div>
          <small className="text-white text-[0.5rem] md:text-lg ">How to</small>
        </a>
      </div>
      {/* Logo of  the app */}
      <div className="flex-auto">
        <Link to={"/dashboard"} className="flex flex-col items-center">
          <img
            className=" h-14 lg:h-16 object-contain"
            src={ImagePath.logoText}
          />
        </Link>
      </div>

      <div className="flex-auto items-center flex-row mx-5">
        <a
          href="#"
          className="flex flex-col items-center  max-w-lg mx-auto rounded-xl  "
          onClick={() => handleSearch()}
        >
          {/* <p className="text-white text-sm font-light flex-1">Search</p> */}
          <div>
            <AiOutlineSearch color="#fff" size={24} />
          </div>
          <small className="text-white text-[0.5rem] md:text-base">
            Search
          </small>
        </a>

        {/* Support of the app */}
      </div>
      <div className="flex-2 ">
        <a
          href="#"
          className="flex flex-col items-center"
          onClick={() => handelSupport()}
        >
          <div>
            <MdOutlinePhoneInTalk color="#fff" size={24} />
          </div>
          <small className="text-white text-[0.5rem] md:text-base">
            Support
          </small>
        </a>
      </div>
      <Search isVisible={isVisible} onClose={handleSearch} />
      <ContactInfoModal
        isSupportVisible={isSupportVisible}
        handelSupport={handelSupport}
      />
    </header>
  );
};

export default Header;

// {/* Support of the app */ }
// {/* <div className='flex-auto '>
//                 <a href='#' className='flex flex-col items-center'>
//                     <div className='mb-1'>
//                         <MdOutlinePhoneInTalk color='#fff' size={22} />
//                     </div>
//                     <small className='text-white text-xs'>Support</small>
//                 </a>
//             </div> */}
// {/* Search of the app */ }
{
  /* How to use */
}
{
  /* <div className='flex-auto ' >
                <a href='#' className='flex flex-col items-center'>
                    <div className='mb-1'>
                        <AiOutlineQuestionCircle color='#fff' size={22} />
                    </div>
                    <small className='text-white text-xs'>How to use</small>
                </a>
            </div> */
}
