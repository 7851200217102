import { useEffect, useMemo, useState } from "react";
import Banner from "../../components/banner/Banner";
import PostList from "../../components/lists/PostList";
import { useAppSelector } from "../../libs/hooks/hooks";
import { FormattedDate } from "../../utils/FormattedDate";
import Loading from "../../components/shared/Loading";

const Lobby = () => {
  const [upcomingpostlist, setUpcomingPostsList] = useState(false);
  const [todaypostlist, setTodayPostsList] = useState(false);
  const [isloader, setIsLoader] = useState(false);
  const todayPostsCategory = [{ data: { ctgName: "today posts" }, id: 1 }];
  const upcomingPostsCategory = [
    { data: { ctgName: "upcoming posts" }, id: 2 },
  ];
  const categoriesImage = useAppSelector(
    (state) => state.dataCollection.imagesCategories
  );
  const categoriesVideo = useAppSelector(
    (state) => state.dataCollection.videosCategories
  );
  const images = useAppSelector((state) => state.dataCollection.images);

  useEffect(() => {
    const todayPostsCategory = [{ data: { ctgName: "today posts" }, id: 1 }];
    const upcomingPostsCategory = [
      { data: { ctgName: "upcoming posts" }, id: 2 },
    ];
    if (todayPostsCategory[0].data.ctgName === "today posts") {
      const todaydata = images.filter((item) => {
        return item.data.date === FormattedDate();
      });
      if (todaydata.length > 0) {
        setTodayPostsList(true);
      }
    }

    if (upcomingPostsCategory[0].data.ctgName === "upcoming posts") {
      const upcomingdata = images.filter((item) => {
        return item.data.date > FormattedDate();
      });

      if (upcomingdata.length > 0) {
        setUpcomingPostsList(true);
      }
    }
  }, [images]);

  return (
    <div>
      {images.length > 0 ? (
        <>
          <Banner />
          <div className="flex gap-1 flex-wrap">
            {todaypostlist &&
              todayPostsCategory.slice(0, 9)?.map((category, index) => {
                return (
                  <PostList
                    categoryItem={category}
                    key={category?.id}
                    isSwiper
                  />
                );
              })}
            {upcomingpostlist &&
              upcomingPostsCategory.slice(0, 9)?.map((category, index) => {
                return (
                  <PostList
                    categoryItem={category}
                    key={category?.id}
                    isSwiper
                  />
                );
              })}
            {}

            {categoriesImage.slice(0, 9)?.map((category, index) => {
              return (
                <PostList categoryItem={category} key={category?.id} isSwiper />
              );
            })}
            {/* {categoriesVideo?.slice(0, 9).map((category, index) => {
              return (
                <PostList categoryItem={category} key={category?.id} isSwiper />
              );
            })} */}
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Lobby;
