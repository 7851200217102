import React, { useCallback, useEffect, useState } from "react";
import firebaseServices from "../../services/firebase/fireabseApi";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

const Banner = () => {
  const [banners, setBanners] = useState([]);

  const fetchBanners = useCallback(async () => {
    const bannersRef = await firebaseServices.getBanners();
    setBanners(bannersRef);
  }, []);

  useEffect(() => {
    fetchBanners();
    return () => {
      // Cleanup function if needed
    };
  }, [fetchBanners]);

  return (
    <div className="px-4 py-3">
      <div className="w-full md:h-72 h-32 overflow-hidden aspect-video rounded-lg">
        {/* {banners?.map((item, index) => { */}
        {/* return ( */}
        <img
          // key={index}
          src={banners[1]?.data.url}
          alt=""
          className="w-full h-full max-w-full object-fill"
          loading="lazy"
        />
        {/* ); */}
        {/* })} */}
      </div>
    </div>
  );
};

export default Banner;
