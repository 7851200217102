import React from 'react'
import Lottie from 'react-lottie-player'
import SplashImage from '../../assets/img/nupost_splash_screen.json'
import LoadingImage from '../../assets/img/loading.json'
const Loading = ({ isLoading=true }) => {
  return (
      <div className='fixed z-[9999] w-full h-full bg-black'>
          <div className='h-screen flex justify-center items-center'>
              {!isLoading ? <div >
                  <Lottie
                      loop
                      animationData={LoadingImage}
                      play
                      style={{ width: 150, height: 150 }}
                  />
              </div> : <div className=''>
                      <Lottie
                          loop
                          animationData={SplashImage}
                          play
                          style={{ width: 400, height: 400 }}
                      />
              </div>}
          </div>
    </div>
  )
}

export default Loading