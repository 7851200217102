import { configureStore } from '@reduxjs/toolkit'
import AuthenticationReducer from './reducers/authentication'
import DataCollectionReducer from './reducers/datacollection'
import CreatePostReducer from './reducers/createpost'

export const store = configureStore({
    reducer: {
        authentication: AuthenticationReducer,
        dataCollection:DataCollectionReducer,
        createPost: CreatePostReducer
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch