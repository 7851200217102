import React from 'react'
import Input from '../../../components/inputs/Input'
import Button from '../../../components/buttons/Button'
import { useForm } from 'react-hook-form';

const ResetPassword = () => {
    const { register, handleSubmit, watch, formState: { errors }, } = useForm();
    const onSubmit = (values) => {

    }
    return (
        <div className='h-full flex justify-center items-center'>
            <form className="max-w-md mx-auto w-[440px] px-5" onSubmit={handleSubmit(onSubmit)}>
                <div className='mb-10'>
                    <h3 className='text-white text-4xl font-semibold text-center'>Reset Password</h3>
                </div>
                <div className="mb-5">
                    <Input type={'password'} placeholder={"**********"} label={"New password"}
                        isError={errors.password}
                        inputProps={{ ...register("password", { required: "Password is required", }) }} />
                </div>
                <div className="mb-5">
                    <Input type={'password'} placeholder={"************"} label={"Confirm password"}
                        isError={errors.confirmPassword}
                        inputProps={{ ...register("confirmPassword", { required: "Confirm Password is required", }) }} />
                </div>             
                <Button label="Submit" type={'submit'} />               
            </form>
        </div>
    )
}

export default ResetPassword