"use client";
import React from "react";

export const ModalLayout = ({
  children,
  title,
  onClose,
  isVisible,
  dialogClass,
  bodyClass,
}) => {
  return (
    isVisible && (
      <>
        <div
          id="default-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="animate-fadeUp overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full flex"
        >
          <div
            className={`relative p-4 w-full max-w-2xl max-h-full ${dialogClass}`}
          >
            {/* Modal content */}
            <div className="relative bg-gray-950 border border-cyan-950 bg-opacity-80 backdrop-blur  rounded-lg shadow">
              {/* Modal header */}
              <div className="flex items-center justify-between p-4 md:p-5 border-b border-b-gray-800 rounded-t">
                <h3 className="text-base font-semibold text-gray-100">
                  {title}
                </h3>
                <button
                  onClick={() => onClose && onClose()}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                  data-modal-hide="default-modal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body */}
              <div className={`p-4 md:p-5 space-y-4 ${bodyClass}`}>
                {children}
              </div>
              {/* Modal footer */}
            </div>
          </div>
        </div>
        <div className="bg-black bg-opacity-70 backdrop-blur-sm  fixed inset-0 z-40"></div>
      </>
    )
  );
};
