

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    postFrameId:1
}

export const CreatePost = createSlice({
    name: 'createpost',
    initialState,
    reducers: {
        setPostFrameId: (state, action) => {
            state.postFrameId = action.payload
        },        
    },
})

// Action creators are generated for each case reducer function
export const { setPostFrameId } = CreatePost.actions

export default CreatePost.reducer